#DT {
    #CONTENT {
        .report {
            padding: 20px;
            float: left;
            height: 625px;

            #template {
                width: 425px;
                height: 550px;
            }
        }
    }

    .report {
        #name {
            text-align: center;
            font-weight: bold;

            .freq {
                color: #999;
            }
        }



        #template {
            //box-shadow: inner 4px 5px 6px red, 4px 4px 19px 6px #d3d2d2;
            box-shadow: 4px 4px 19px 6px #d3d2d2;
            font-family: monospace;
            font-size: 10px;
            margin: 10px;
            padding: 10px;
            border: 2px solid #999; //transition: transform ease-out 0.3s;



            //
            .gaugebox {
                display: flex;
                flex-wrap: wrap;
                text-align: center;

                figurebox {
                    flex: 1;
                }

                .gauge {
                    display: inline-block;
                    position: relative;
                    width: 100px;
                    height: 50px;
                    overflow: hidden;
                }

                .gauge:after,
                .gauge:before,
                .meter {
                    position: absolute;
                    display: block;
                    content: "";
                }

                .gauge:before,
                .meter {
                    width: 100px;
                    height: 50px;
                }

                .gauge:before {
                    border-radius: 50px 50px 0 0;
                    background: #ccc;
                }

                .gauge:after {
                    position: absolute;
                    bottom: 0;
                    left: 25px;
                    width: 50px;
                    height: 25px;
                    background: #fff;
                    border-radius: 25px 25px 0 0;
                }

                .meter {
                    top: 100%;
                    transition: 1.5s;
                    transform-origin: center top;
                    border-radius: 0 0 60px 60px;
                    background: #069;
                    border: none;
                }

                figcaption {
                    text-align: center;
                }
            }

            .chart {
                width: 100%;

                tr {
                    margin: 0;
                }

                td {
                    margin: 0;
                }

                .name {
                    width: 20%;
                }

                .value {
                    width: 15%;
                    text-align: right;

                    .unit {
                        color: #999;
                    }
                }

                .barbox {
                    width: 65%;
                    background: repeating-linear-gradient(to right, #bbb, #bbb 1px, #ccc 1px, #ccc 10%);

                    .bar {
                        height: 10px;
                        background-color: #069;
                    }
                }
            }

            table {
                td {
                    padding: 1px;
                    text-align: initial;
                    //border: 1px solid #efefef;

                    hr {
                        height: 1px;
                    }
                }

                td.right,
                th.right {
                    text-align: right;
                }

                td.left,
                th.left {
                    text-align: left;
                }
            }
        }

        #watermark {
            color: black;
            font-size: 70px;
            transform: translate(0px, -350px) rotate(45deg);
            text-align: center;
            opacity: 0.05;
            font-family: monospace;
        }
    }

    .report.active {
        background-color: white;
        border-radius: 10px;

        #template {
            border-color: black;
            box-shadow: 4px 4px 48px 11px #d3d2d2;
            background-color: white;
            overflow: auto; //transform: scale(1.1);
        }
    }

    #DETAILS {
        .reports {
            display: flex;
            flex-direction: column;
            height: 100%;

            .template {
                height: 100%;

                // border: 1px solid red;
                textarea#template {
                    height: 50%;
                    box-sizing: border-box;
                }
            }

            sup {
                color: #999;
            }
        }

        .reports.full {
            $margin: 0px;
            position: fixed;
            left: $margin;
            top: $margin;
            right: $margin;
            bottom: $margin;
            overflow: hidden;
            background-color: #eee;
            //height: 500px;

            .value {
                font-family: monospace;

                .unit {
                    color: #aaa;
                }
            }

            table {
                padding: 10px;

                td {
                    text-align: left;
                }

                th.right,
                td.right {
                    text-align: right;
                }
            }

            // table {
            //     height: 100%;
            //     padding: 10px;

            // }
            #fullscreen {
                //border: 1px solid red;
                position: absolute;
                padding: 10px;

                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                display: flex;

                div {
                    width: 50%;
                    padding: 0 5px;
                }

            }


            div#template {
                border: 2px solid #999;
                box-shadow: 4px 4px 19px 6px #d3d3d3;
                padding: 20px;
                max-height: 100%;
                text-align: left;
                background-color: white;
                overflow: auto;
            }

            textarea#template {
                position: inherit;
                width: 100%;
                height: 100%;
                padding: 5px;
            }
        }

        table#hours {
            font-size: 10px;
            border: 2px inset #ccc;
            background-color: #ccc;
            border-radius: 5px;
            padding: 3px;

            td {
                text-align: center;
            }
        }

        .empty {
            fieldset {
                display: flex;
            }
        }

        // .flex {
        // 	display: flex;
        // 	flex-direction: column;
        // 	position: absolute;
        // 	top: 60px;
        // 	bottom: 50px;
        // 	right: 5px;
        // 	left: 5px;
        // 	//border: 1px solid red;
        // 	& > div {
        // 		//	border: 1px solid blue;
        // 		flex-grow: 1;
        // 	}
        // }
        textarea#template {
            font-family: monospace; //white-space: pre-wrap;
            // position: absolute;
            // bottom: 50px;
            // top: 407px;
            // width: 298px;
            // height: inherit;
        }

        pre {
            background-color: white;
            padding: 10px;
            font-size: 10px;
        }

        #fullscreen_link {
            float: right;
            position: relative;
            top: -29px;
        }
    }

    #CONTENT #template,
    .panel #template {
        overflow: hidden;

        table {
            width: 100%;

            th {
                text-align: left;
            }

            td {
                white-space: nowrap;
            }
        }

        .value {
            .unit {
                color: #999;
            }
        }

        .value {
            font-family: monospace;

            .unit {
                color: #aaa;
            }
        }

        h1 {
            font-size: 20px;
            font-weight: bold;
            background-color: inherit;
            color: black;
            border-bottom: 6px solid black;
            text-align: left;
        }

        h2 {
            font-size: 18px;
            font-weight: bold;
            background-color: inherit;
            color: black;
            border-bottom: none;
            border-bottom: 4px solid black;
            text-align: left;
        }

        h3 {
            font-size: 16px;
            font-weight: bold;
            background-color: inherit;
            color: black;
            border-bottom: none;
            border-bottom: 2px solid black;
            text-align: left;
        }
    }
}