#event_log_box {

    &.none {
        display: none;
    }

    &.wide {
        .table {
            width: 100%;
            display: table;
        }

        .tr {
            display: table-row;
            padding-bottom: 5px;
            font-size: 12px;
        }

        .td {
            vertical-align: top;
            display: table-cell;
            padding: 2px;
        }
    }

    &.narrow {
        .table {
            width: 100%;
        }

        .tr {
            padding-bottom: 5px;
            margin-bottom: 10px;
            border-bottom: 1px solid #555;
            font-size: 12px;
        }

        .td {
            &.datetime {
                padding-bottom: 5px;
            }

            &.alarm_state {
                float: left;
            }
        }
    }

    #event_list {
        overflow-x: hidden;
        overflow-y: auto;
        flex: 1;
        text-align: top;
        color: white;
        box-sizing: border-box;
        border-collapse: collapse;

        .td {
            .name {
                color: white;
                cursor: pointer;
            }

            .alarm {
                color: red;
            }

            .warning {
                color: yellow;
            }

            .normal {
                color: green;
            }

            .off {
                color: #555;
            }
        }

        .tr.timebreak {
            .td {
                padding-top: 30px;
            }
        }

        .tr.timebreak2 {
            .td {
                padding-top: 100px;
            }
        }

        .tr.alarm {
            color: red;
        }

        .tr.warning {
            color: yellow;
        }

        .tr.normal {
            color: #0f0;
        }

        .event {
            color: #999;
        }
    }
}

.PAGE {

    .prefix,
    .level {
        float: right;
        border-radius: 5px;
        padding: 5px 10px;
        margin: 25px 15px;
        cursor: pointer;

        &.on {
            border: 4px inset #999;
            background-color: $lighter;
        }

        &.off {
            border: 4px outset #999;
            background-color: $darker;
        }
    }

}


//
// .PAGE {
// 	& > div {
// 		position: absolute;
// 		left: 0;
// 		right: 0;
// 	}
//
// 	#header {
// 		top: 0;
// 		height: 100px;
// 		background-color: #033;
// 	}
//
// 	#footer {
// 		background-color: #033;
// 		bottom: 0;
// 		height: 50px;
// 	}
//
// 	#page_box {
// 		top: 100px;
// 		bottom: 50px;
// 		left: 10px;
// 		right: 10px;
// 		overflow: auto;
//
// 		#event_list {
// 			width: 100%;
// 		}
// 	}
// }
