& {
	fieldset {
		color: white;
		font-size: 14px;
		font-weight: bold;
		margin-top: 0;
		pointer-events: none;

		&.noborder {
			border: none;
		}
	}

	#widget_box {
		display: flex;
		flex-wrap: wrap;
		position: absolute;
		left: 0;
		top: 0;
		//border: 1px solid red;
		margin-bottom: 10px;
		overflow: hidden;

		&.spread {
			justify-content: space-around;
			align-content: center;
		}

		.widget {
			flex-grow: 0;
			position: relative;
			left: 0 !important;
			top: 0 !important;

			&.box {
				border: 1px dashed #555;
			}
		}
	}
}
