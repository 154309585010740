/**
 * Please note the structure of this document
 *
 * DT        : Primary layout
 * - MENU    : menu CSS
 * - MAIN    : main CSS
 * - DETAILS : details CSS
 *
 * DT
 * - DETAILS
 * -- devices, tag_library...
 * - MAIN
 * -- devices, tag_loibary...
 *
 *
 *
 *
 */

#DT {
    // PRIMARY LAYOUT
    $left_size: 150;
    $right_size: 300;
    $dark: #333;
    $light: #fff;

    #showhide {
        top: 5px;
        right: 5px;
    }

    #showhelp {
        top: 25px;
        right: 5px;
    }

    .tinybuttons {
        position: absolute;
        cursor: pointer;
        z-index: 3;

        &.showdetails {
            background-color: black;
            color: $link_color_alt;
        }

        &.hidedetails {
            color: $link_color;
        }
    }

    #HELPPANEL {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 50;
        background-color: #eee;
        width: 285px;
        right: 0;
        padding: 10px;
        z-index: 1;
        transition: right 200ms; //box-shadow: 5px 0 60px 10px #000;
        border-left: 5px solid black;
    }

    #MENU {
        &::-webkit-scrollbar {
            display: none;
        }

        padding-top: 10px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: #{$left_size}px;
        background-color: #000;
        color: #aaa;
        overflow-y: auto;
        overflow-x: hidden;

        #exit {
            text-align: center;
            position: fixed;
            left: 0;
            bottom: 0;
            width: #{$left_size}px;
            padding-bottom: 10px;
            padding-top: 10px;
            border-top: 5px solid white;
            cursor: pointer;
            background-color: $dark;
            box-shadow: 0 -7px 18px $dark;

            &:hover {
                background-color: white;
                color: black;
            }
        }

        table {
            width: 100%;

            th {
                font-weight: bold;
                text-align: left;
            }

            td {
                text-align: right;
            }

            td:last-child input {
                text-align: right;
            }
        }

        .menubox {
            margin-top: 10px;
            padding: 15px 10px;
            border-top: 5px solid white;
            line-height: 30px;
        }

        .menuitem {
            user-select: none;
            height: 21px;
            padding: 8px;
            cursor: pointer;
            color: white;

            &.disabled {
                opacity: 0.5;
            }

            &.active {
                background-color: white;
                color: black;
            }
        }

        #machine_status {
            line-height: 20px;

            .machine {
                padding-top: 5px;

                .machine_tags {
                    .tag {
                        height: 20px;
                        cursor: pointer;

                        i {
                            width: 20px;
                            text-align: center;
                        }

                        meter {
                            width: 95px;
                            height: 10px;
                        }
                    }
                }
            }
        }

        #poller_status {
            .poller {
                cursor: pointer;
                white-space: nowrap;
                color: white;

                #icon {
                    float: left;
                    width: 25px;
                    text-align: center; //					border: 1px solid red;
                }

                #name {
                    width: 73px;
                    overflow: hidden;
                    text-overflow: ellipsis; //	border: 1px solid blue;
                }

                #status {
                    //	border: 1px solid green;
                    float: right;
                    border: 2px outset white;
                    width: 8px;
                    height: 8px;
                    background-color: #000;
                    border-radius: 7px; //float: left;
                    margin-top: 10px;
                    margin-right: 5px;
                    margin-left: 5px;

                    &.ONLINE {
                        background-color: #5f5;
                    }

                    &.WARNING {
                        background-color: yellow; //box-shadow: 0 0 9px 6px rgba(255,255,0,0.3);
                    }

                    &.OFFLINE {
                        background-color: #f55;
                        -webkit-animation: redblink 1s;
                        -webkit-animation-iteration-count: infinite;
                    }
                }
            }
        }
    }

    #CENTER {

        h1 {
            margin: 0px -5px;
            padding-right: 140px;
            padding-top: 4px;
            background-color: #000;
            color: white;
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }


    }

    #CONTENT {
        overflow: auto; // overflow-x      : hidden;
        position: absolute;
        top: 46px;
        left: #{$left_size}px;
        bottom: 0;
        padding: 15px;
        background-color: white;
        user-select: none;

        &.showdetails {
            right: #{$right_size +15}px;
            transition: right 0.3s;
        }

        &.hidedetails {
            right: 0;
            transition: right 0.3s;
        }

        .active {
            background-color: rgba(0, 0, 0, 0.1); //box-shadow      : 0 0 1em 2px $link_color;
            box-shadow: 0 0 0 4px white, 0 0 0 8px $link_color;
        }

        h1 {
            margin: 5px -5px;
            padding: 4px;
            background-color: black;
            color: white;
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    #DETAILS {
        &::-webkit-scrollbar {
            display: none;
        }

        overflow-y: auto;
        overflow-x: hidden;
        position: absolute;
        padding: 5px 5px 45px;
        top: -10px;
        right: 0;
        bottom: 0;
        background-color: #ddd;
        border-left: 3px solid black;
        width: #{$right_size}px;
        z-index: 3;

        .message {
            font-style: italic;
            text-align: center;
            color: #777;
        }

        .empty {
            p {
                text-align: justify;
                padding-left: 10px;
                padding-right: 10px;
            }

            #icon {
                text-align: center;
                font-size: 150px;
                padding: 20px;
                text-shadow: 2px 2px 1px #fff;
            }

            dl {
                padding-left: 20px;
                padding-right: 20px;
            }
        }

        &.showdetails {
            transition: transform 0.3s;
        }

        &.hidedetails {
            transform: translate(315px);
            transition: transform 0.3s;
        }

        .btn {
            // width: 280px;
            width: 94%;
            box-sizing: border-box;
        }

        dl {
            padding: 10px;

            dt {
                padding-top: 5px;
                font-weight: bold;
            }
        }

        #new_record {
            border: 3px dashed $link_color;
            font-weight: bold;
            margin-bottom: 10px;
            padding: 10px;
            font-size: 18px;
            border-radius: 5px;
            background-color: $darker;
        }

        input:not([type='checkbox']),
        select {
            width: 97%;
        }

        #image_url {
            width: 67%;
            margin-right: 5px;
        }

        fieldset {
            legend {
                select.field {
                    width: auto;
                }
            }
        }

        input.small,
        select.small {
            width: auto;
        }

        .field::placeholder {
            color: #bbb;
        }

        .field:disabled {
            border: 1px solid #ddd;
            background-color: #eee;
        }

        .field:invalid {
            border: 1px solid red;
        }

        textarea {
            width: 100%;
        }

        textarea.full {
            height: 100px;
            width: 100%;
            box-sizing: border-box;
        }

        textarea.nowrap {
            white-space: pre;
        }

        .json {
            box-sizing: border-box;
            height: 150px;
            width: 100%;
            font-family: monospace;
            tab-size: 3;
            white-space: pre;

            &.badjson {
                background-color: #fee;
            }
        }

        #details_footer {
            position: fixed;
            right: 0;
            bottom: 0;
            width: #{$right_size + 10}px;
            height: 40px;
            background-color: #333;
            color: #888;
            border-top: 5px solid black;
            display: flex;
            user-select: none;

            .link {
                flex-grow: 1;
                padding: 10px;
                color: white;
                text-align: center;

                &:hover {
                    background-color: white;
                    color: black;
                }
            }
        }

        h1 {
            margin: 5px -5px;
            padding: 4px;
            background-color: black;
            color: white;
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        h2 {
            margin: 5px -5px;
            text-align: left;
            border-bottom: 5px solid black;
            padding-left: 5px;
            margin-top: 15px;

            a {
                text-decoration: none;
            }
        }

        .note {
            padding: 5px;
            margin: 0;
            color: #888;
            font-size: 12px;
        }

        table {
            width: 100%;

            th {
                white-space: nowrap;
                font-weight: bold;
                text-align: left;
                padding-left: 10px;
                text-indent: -10px;
            }

            td {
                text-align: right;
            }

            td:last-child input {
                text-align: right;
            }
        }
    }
}

@media (max-width: 800px) {
    #DT {
        #MENU {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            height: 35px;
            width: 100%;
            padding-top: 0;

            #exit {
                position: absolute;
                right: 0;
                top: 0;
                left: auto;
                font-size: 0;
                width: 40px;
                border-top: none;
                color: white;
                border-left: 5px solid white;
                padding-top: 5px;

                i {
                    font-size: 20px;
                }
            }

            .menuitem {
                font-size: 0;
                float: left;

                i {
                    font-size: 20px;
                }
            }

            .menubox {
                display: none;
            }
        }

        #CONTENT {
            &.showdetails {
                left: 0;
                top: 35px;
                right: 0;
                bottom: auto;

                #DATABASE {
                    height: auto;
                }
            }
        }

        #DETAILS {
            &.empty {
                display: none;
            }

            top: 35px;
            width: 100%;
            left: 0;
            border-left: none;
            background-color: white;

            #details_footer {
                width: 100%;
            }
        }

        #showhide {
            display: none;
        }
    }
}

#widget_list {
    background-color: #bbb;
    position: absolute;
    right: 0;
    bottom: 40px;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: flex-end;
    z-index: 10;
    padding: 10px;
    border-left: 8px solid $link_color;
    border-top: 8px solid $link_color;
    border-top-left-radius: 12px;

    .group {
        .title {
            font-weight: bold;
            border-bottom: 1px solid black;
            margin-right: 5px;
        }

        .widget_tiles {
            .widget_tile {
                padding: 5px 10px;
                cursor: pointer;
                white-space: nowrap;

                i {
                    width: 20px;
                    text-align: center;
                }

                &:hover {
                    color: $link_color;
                }
            }
        }
    }
}

#widget_edit_menu {
    $border: 1px solid #ccc;
    background-color: #eee;
    border-top: 8px solid $link_color;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 40px;
    padding: 0;
    z-index: 10;
    display: flex;
    box-shadow: 0 -6px 7px black;
    white-space: nowrap;

    &>div {
        text-align: center;
        flex-grow: 1;
        padding: 10px;
    }

    .bar_box {
        border-right: 1px solid $darker;
        float: left;
        padding-left: 10px;
        padding-right: 10px;

        &:last-child {
            border-right: none;
        }
    }

    #toolbar {
        padding-left: 10px;
        overflow: hidden;
        height: 12px; // WHAT?!  how does this hide wrapping properly

        .toolset {
            float: left;
            display: flex;
            flex-wrap: nowrap;
            user-select: none;

            .label {
                padding: 2px;
                margin-right: 0;
                margin-left: 0;
                border-radius: 0;
                border-right: 2px solid $darker;
            }

            &.expanded .label {
                color: red;
            }

            .tools {
                box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.5);
                display: none;
                white-space: nowrap;
                height: 20px;
                overflow: hidden;
                background-color: rgba(0, 0, 0, 0.1);
                margin-right: 0;
                margin-left: 0;
                padding: 1px 10px; //width: 100px;

                .options {
                    font-size: 12px;
                    float: left;
                    padding-left: 5px;
                    padding-right: 5px;
                    padding-top: 3px;
                    flex-grow: 0;
                    white-space: nowrap;
                    color: #555;
                }

                .tool {
                    float: left;
                    color: $link_color;
                    padding-left: 5px;
                    padding-right: 5px;
                    padding-top: 1px;
                    flex-grow: 0;
                    white-space: nowrap;
                    cursor: pointer; // align-items    : center;
                    // justify-content: center;
                    border-right: 1px solid $darker;

                    &.disabled {
                        opacity: 0.5;
                        pointer-events: none;
                    }

                    &:last-child {
                        border-right: none;
                    }

                    &:hover {
                        background-color: $darker;
                        color: $link_color_alt;
                    }
                }
            }
        }
    }

    #sheets {
        padding-left: 10px;
        text-align: left;
        width: 105px;
        border-right: $border;
        flex-grow: 0;
    }

    #toolset {
        .label {
            font-weight: bold;
            padding-left: 5px;
        }

        .tool {
            cursor: pointer;
        }
    }

    #commands {
        display: flex;
        text-align: right;
        width: 250px;
        flex-grow: 0;

        .button {
            top: -3px;
            position: relative;
            height: 26px;
            width: 120px;
        }
    }

    #dimensions {
        input {
            width: 45px;
            text-align: right;
        }
    }

    #title {
        font-weight: bold;
        text-align: left;
        padding-left: 10px;
        white-space: nowrap;
    }

    #exit {
        border-right: $border;
        cursor: pointer;
        max-width: 150px;
        min-width: 50px;
        flex-grow: 0;
        background-color: $darker;
    }

    #settings_box {
        white-space: nowrap;
    }

    #settings {
        background-color: $darker;
        width: 50px;
        cursor: pointer;
        border-left: $border;
        flex-grow: 0;
    }

    #widgets {
        border-left: $border;
        cursor: pointer;
        max-width: 150px;
        min-width: 50px;
        flex-grow: 0;
        background-color: $darker;

        &.selected {
            background-color: #bbb;
        }
    }
}

body>#widget_props {
    z-index: 25;
    background-color: #bbb;
    position: absolute;
    padding: 10px;
    box-shadow: 4px 2px 30px 5px #000;
    border-radius: 3px;
    font-size: 14px;
    opacity: 0; // hide until it positions.

    #helpbox {
        color: #666;
        font-style: italic;
        border-top: 2px dashed #999;
        margin-top: 5px;
        padding-top: 5px;

        &:empty {
            border-top: none;
        }
    }

    textarea {
        width: 100%;
        height: 40px;

        &.tall {
            height: 100px;
        }
    }

    table {
        width: 100%;
    }

    th {
        white-space: nowrap;
        font-weight: bold;
        user-select: none;
        text-align: right;
        padding-right: 5px;
    }

    .tabs {
        margin-top: 5px;
        margin-bottom: 5px;
        border: 1px solid #888;
        background-color: #aaa;
        border-radius: 3px;

        #body {
            padding: 5px;
        }

        #tabbox {
            display: flex;
            background-color: #888;
            margin-bottom: 5px;

            .tab {
                cursor: pointer;
                flex-grow: 1;
                border-right: 1px dashed #666;
                padding-left: 5px;
                padding-right: 5px;
                text-align: center;

                &.label {
                    cursor: default;
                    pointer-events: "none";
                    border-right: 1px solid #666;
                    color: #555;
                    background-color: $darker;
                }

                &.active {
                    color: $link_color_alt;
                }
            }
        }
    }

    .tagchooser {
        // this is the field, inside the props.
        border: 1px solid $link_color;
        background-color: white;
        cursor: pointer;
        min-height: 25px;
        min-width: 100px;
        border-radius: 5px;
        max-height: 300px;
        overflow: auto;

        &>.item {
            //	background-color         : $link_color;
            padding: 3px;
            margin: 2px;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px; //color                    : white;
            font-size: 12px;
            padding-left: 11px;
            padding-right: 5px;

            .delete {
                float: right;
                color: $link_color;

                &:hover {
                    color: $link_color_alt;
                }
            }
        }

        &.active {
            border-color: $link_color_alt;
        }

        #chooser {
            position: absolute;
            box-shadow: 0 0 20px 7px rgba(0, 0, 0, 0.5);
            max-height: 200px;
            overflow: auto;
            background-color: white;
            padding: 5px;

            thead {

                input,
                select {
                    width: 100%;
                    min-width: 80px;
                }

                td {
                    background-color: #ddd;
                    padding: 5px;
                }
            }

            .link {
                color: $link_color;

                &:hover {
                    color: $link_color_alt;
                }
            }

            td {
                white-space: nowrap;
            }

            #newtag {
                border: 2px solid $link_color_alt;
                width: 100%;
                box-sizing: border-box;
            }

            #items {
                background-color: white;
                max-height: 200px;
                overflow-y: auto;
                overflow-x: hidden;
                padding: 5px;

                .item {
                    padding: 2px;
                    white-space: nowrap;

                    .desc {
                        color: #999;
                        float: right;
                        max-width: 300px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }

                    &:hover {
                        color: $link_color;
                    }
                }
            }
        }
    }

    #tagchooser_box {
        // this is the popup, when selecting.
        border: 1px solid blue;
        position: fixed;
        background-color: white;
        padding: 5px;
    }

    .json {
        font-family: monospace;
        tab-size: 3;
        white-space: pre;

        &#json {
            // width: 200px;
            height: 200px;
        }

        &.badjson {
            background-color: #fee;
        }
    }

    #header {
        border-bottom: 2px solid black;
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 5px;

        #title {
            font-weight: bold;
            flex-grow: 1;
            user-select: none;
            cursor: move;
        }

        .command {
            text-align: right;
            color: $link_color;
            cursor: pointer;
            padding-left: 5px;

            &:hover {
                color: $link_color_alt;
            }
        }
    }
}

.button {
    border-radius: 5px;
    width: 100px;
    margin-right: 5px;
    margin-left: 5px;
    text-align: center;
    background-color: $link_color;
    color: white;
    font-size: 15px;
    cursor: pointer;
}

.fontawesomechooser {
    width: 37px;
    float: right;

    .preview {
        background-color: white;
        padding: 10px;
        color: black;
        border-radius: 5px;
        cursor: pointer;
    }

    #icons {
        display: none; // toggles to "flex"
        position: absolute;
        right: 10px;
        overflow: hidden;
        background-color: white;
        color: black;
        padding: 10px;
        flex-wrap: wrap;
        height: 120px;
        width: 200px;
        overflow-y: auto;
        box-shadow: 1px 2px 19px 3px #999;

        #icon_list {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
        }

        #search {
            width: 100%;
        }

        .tile {
            flex-grow: 1;
            padding: 5px;
            cursor: pointer;
            border: 1px solid white;
            border-radius: 3px;

            &:hover {
                border: 1px solid $link_color_alt;
            }
        }
    }
}

@import "../help/help.scss";