&.widget {
	overflow: visible;
	background-color: rgba(0,0,0,0.5);

	fieldset {
		margin-top: auto;
		border-color: #0FF;
		border-width: 3px;
		text-align: center;
		cursor: pointer;
		background-color:rgba(0, 0, 0, 0.8);

		&.shadow {
			box-shadow:0px 0px 40px 40px rgba(0, 0, 0, 0.8);
			z-index: 100;
		}

		#inputbox {
			display: none;
			input {
				width: 90%;
				text-align: center;
			}
		}
	}
	#keypad {
		// position: absolute;
		// left: 20px;
		// top: 50px;
		height: auto;
		display: none;
		z-index: 20;
		
		table {
			padding-top: 5px;
			width: 100%;
			min-width: 130px;

			tbody {
				td {
					width: 33%;
					border: 2px solid #00ffff;
					text-align: center;
					padding: 5px;
					border-radius: 5px;
					cursor: pointer;
					transition: all .1s ease-in-out; 
					background-color: #000;

					.label {
						display: none;
					}

					&:active {
						border-color: #fff;
						color: #fff;
						transform: scale(1.2)
					}
				}
			}
			tfoot {
				td {
					text-align: center;
					font-size: 10px

				}
			}
			thead {
				td {
					font-size : 10px;
					text-justify: full;
				}
			}
		}
	}
}
