#DT {
    #tag_details {
        .nav {
            td {
                border-bottom: 2px solid black;
                color: red;
                padding-bottom: 5px;

                &.arrow {
                    color: #069;
                    cursor: pointer;

                    &#right {
                        text-align: right;
                    }

                    &#left {
                        text-align: left;
                    }
                }
            }
        }
    }

    #TAGS {
        display: flex;
        flex-wrap: wrap;
        align-content: stretch;
        font-size: 12px;
        max-height: 400px;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 3px;

        .tag {
            flex-grow: 1;
            flex-basis: 0;
            border: 1px solid black;
            padding: 2px;
            border-radius: 3px;
            margin: 5px;
            min-width: 160px;
            float: left;
            cursor: pointer;
            font-family: monospace;
            color: #777;
            padding: 3px;


            .symbol {
                color: #111;
                font-weight: bold;

                .address {
                    font-weight: normal;
                    color: #777;
                    padding-left: 10px;
                }

                i {
                    padding-right: 2px;
                    position: relative;
                    top: 1px;
                }
            }

            .value {
                color: black;
                text-align: right;
                font-weight: bold;
            }

            .state {
                flex-grow: 0 !important;
                flex-shrink: 0;
                flex-basis: 65px;
                text-align: right;
                border-radius: 3px;
                width: 100px;

                &.alarm {
                    background-color: red;
                    text-align: center;
                    color: white;
                    text-transform: uppercase;
                }

                &.warning {
                    background-color: yellow;
                    text-align: center;
                    color: black;
                }

                &.normal {
                    display: none;
                }

            }

            .name {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-family: "Lucida Grande", Lucida, Verdana, sans-serif;
            }

            .unit {
                color: #bbb;
            }

            .tinyflex {
                display: flex;

                div {
                    flex-grow: 1;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }

                .address {
                    padding-left: 5px;
                    padding-right: 5px;
                }

                .symbol,
                .value {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

            }

            i {
                color: #777;
            }

            &.never {
                border-style: dashed;
            }

            table {
                width: 100%;

                .alarm {
                    color: red;
                }

                .warning {
                    color: #770;
                }

                &.new {
                    opacity: 0.5;
                }
            }

            @keyframes yellow-fade {
                0% {
                    background: rgba(0, 255, 255, 0.2);
                    color: black;
                }

                100% {
                    background: rgba(0, 255, 255, 0);
                }
            }

            &.changed {
                animation: yellow-fade 2s ease-in 1;
            }
        }
    }
}