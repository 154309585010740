body,
html {
    height: 100%;
    width: 100%; //  position: fixed; // disable bounce-scrolling for ipads
}

#RT {
    display: flex;
    overflow: hidden; //	background-color: #011;



    ::-webkit-scrollbar {
        width: 15px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #111;
        border-radius: 3px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #0ff;
        border-radius: 3px;
    }

    //   ::-webkit-scrollbar-track-piece {
    //       background-color: red;
    //   }
    ::-webkit-scrollbar-button {
        display: none;
        background-color: #0ff;
        border-radius: 3px;
    }

    ::-webkit-scrollbar-button:hover {
        background-color: #fff;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #fff;
    }


    &.bottom #MAIN_BOX {
        flex-direction: column;

        #alarm_list_box,
        #event_log_box {
            width: 50%;
        }

        #EXTRA_BOX {
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            border-top: 3px dashed #033;
            background-color: #011;
            user-select: none;
            overflow: hidden;

            #event_log_box {
                flex-grow: 1;
            }

            #alarm_list_box {
                flex-grow: 2;
                border-left: 3px dashed #033;
                max-width: 75%;

                #alarm_list {
                    align-content: flex-start;
                }
            }
        }
    }

    &.right #MAIN_BOX {
        flex-direction: row;

        #EXTRA_BOX {
            display: flex;
            flex-direction: column; // width         : 100px;
            border-left: 3px dashed #033;

            #alarm_list_box {
                min-height: 20%;

                #alarm_list {
                    //align-content: flex-end;
                    border-top: 3px dashed #033; //max-height: 50%;
                    overflow-y: hidden;
                    overflow-x: hidden;
                }
            }

            #event_log_box {
                //max-height: 50%;
                flex-basis: 1;
                overflow-y: auto;
                overflow-x: hidden;

                #event_list {
                    margin-bottom: 6px;
                    max-height: 50%;
                }
            }

            //	}
            #MENU_BOX {
                flex-grow: 1;
                min-height: min-content;
                padding-right: 3px;

                #MENU {
                    flex-wrap: wrap;
                }
            }
        }
    }


    #MAIN_BOX {
        display: flex;
        overflow: hidden;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        transition: margin-bottom 200ms ease-in-out;

        &.bottom_bar_margin {
            margin-bottom: 50px;
            background: repeating-linear-gradient(45deg, transparent, transparent 10px, #110 10px, #110 20px);
        }

        #SCREEN_BOX {
            overflow: hidden;
            flex-grow: 0;
            flex-shrink: 0;

            > svg {
                //border: 1px blue dashed;
                position: absolute;
                z-index: -1;
            }

            &.zoomed {
                overflow: scroll;
            }

            #screen {
                // outline: 5px solid #999;
                // outline-offset: -1px;
                width: 100vmin;
                height: 100vmin;
                box-sizing: border-box;
                overflow: hidden;
                background-color: black;
                border-radius: 5px;
                transform-origin: top left; //	box-shadow      : inset 0 0 40px #555;
                //	border          : 1px solid #0ff;
                border-radius: 5px;
                user-select: none;
                -webkit-user-select: none;

                .tooltip {
                    cursor: pointer;
                }

                .snap {
                    position: absolute;
                    border: 1px solid $link_color;
                    opacity: 0.0;
                    pointer-events: none;
                }

                .snap_border {
                    transition: opacity 3.0s ease-out;
                    opacity: 0.3;
                }

                #marquee {
                    position: absolute;
                    left: -10px;
                    top: -10px;
                    width: 0;
                    height: 0;
                    border: 2px dashed $link_color;
                    cursor: crosshair;
                }
            }
        }

        #EXTRA_BOX {
            flex: 1; //font-size: 12px;

            .bold,
            .value {
                font-family: monospace;
                color: white;
            }

            &.hideborder {
                display: block;
                border-top: none !important;
                border-left: none !important;
                width: 0;
                height: 0;
                background-color: black;
            }

            .note {
                color: #aaa;
                border: none;
            }

            // #alarm_list_box,
            // #event_log_box {
            //     //padding: 20px;
            // } // div {
            // 	flex-grow: 1;
            // }
            #alarm_list_box {
                overflow-x: hidden;
                overflow-y: auto;

                #alarm_list {
                    // display: flex;
                    // flex-wrap: wrap;
                    align-items: center;
                    align-content: center;
                    //justify-content: space-around;
                    overflow-x: hidden;
                    overflow-y: auto;

                    &>div.row {
                        float: left;
                        padding: 5px 15px;
                        cursor: pointer;
                    }
                }
            }

            #event_log_box {
                overflow-x: hidden;
                overflow-y: auto;
            }

            //	}
            #MENU_BOX {
                overflow: hidden;
                flex-grow: 0;

                #BURGER {
                    position: absolute;
                    right: 7px;
                    bottom: 5px;
                    font-size: 30px;
                    color: #0ff;
                    background-color: rgba(0, 0, 0, 0.7);
                    width: 40px;
                    height: 38px;
                    text-align: center;
                    border-radius: 5px;
                    cursor: pointer;
                    border: 3px solid rgba(0, 0, 0, 0);
                    text-shadow: #005 0 0 10px;

                    &.active {
                        border: 3px solid $link_color_alt;
                    }

                    &:hover {
                        color: white;
                    }
                }

                // #fullscreen {
                //     position: relative;
                //     bottom: 5px;
                //     right: 60px;
                //     font-size: 30px;
                //     color: #0ff;
                //     background-color: rgba(0, 0, 0, 0.7);
                //     width: 40px;
                //     height: 38px;
                //     text-align: center;
                //     border-radius: 5px;
                //     cursor: pointer;
                //     border: 3px solid rgba(0, 0, 0, 0);
                //     text-shadow: #005 0 0 10px;
                //     border: 3px solid $link_color_alt;
                //     &:hover {
                //         color: white;
                //     }
                // }
                #menu {
                    display: none;
                    $main_height: 105px;
                    $status_height: 41px;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background-color: #555;
                    width: 100%;
                    height: 160px;
                    padding-left: 10px;
                    padding-top: 10px;
                    border-top: 5px solid $link_color;
                    box-shadow: 0 -5px 37px 18px black;

                    #main_row {
                        //	border: 1px solid green;
                        display: flex;
                        height: $main_height;
                        // margin-right: 135px;
                        margin-bottom: 7px;

                        #user {
                            margin-right: 10px;
                            background-color: white;
                            border-radius: 10px;
                            text-align: center;
                            padding: 3px;
                            cursor: pointer;
                            width: 125px;

                            svg {
                                font-size: 100px;
                            }

                            img {
                                height: $main_height - 6;
                                background-color: white;
                                border-radius: 10px;
                                text-align: center;
                                cursor: pointer;
                            }
                        }

                        #screens {
                            height: $main_height; // position: absolute;
                            // left: 140px;
                            // right: 220px;
                            // bottom: 46px;
                            overflow: hidden;
                            background-color: #111;
                            border-radius: 10px;
                            color: #aaa;
                            width: 100%;

                            #SCREENS {
                                display: flex;
                                overflow-y: hidden;
                                overflow-x: auto;
                                margin-right: 10px;
                                margin-left: 10px;

                                .screen {
                                    //width: 150px;
                                    //flex-grow: 1;
                                    text-align: center;
                                    margin: 10px;
                                    cursor: pointer;
                                    float: left;
                                    border-radius: 10px;

									// &.disabled {
									// 	display: none;
									// }

                                    .dim {
                                        display: none;
                                    }

                                    .preview {
                                        background-color: #111;
                                        background-image: radial-gradient(ellipse farthest-corner at center, #004880 0%, #06013B 100%);
                                        color: white;
                                        border-radius: 5px;
                                        margin-left: auto;
                                        margin-right: auto;
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        position: relative;
                                        font-size: 40px;
                                        border: 3px solid #555;
                                        overflow: hidden;

                                        &:hover {
                                            border: 3px solid $link_color_alt;
                                        }

                                        &.selected {
                                            border: 3px solid white;
                                        }

                                        .widget {
                                            background-color: rgba(255, 255, 255, 0.5);
                                            position: absolute;
                                            border-radius: 2px;
                                            box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.7);
                                            font-size: 1.5vmin;
                                            color: rgba(0, 0, 0, 0.5);
                                            overflow: hidden;
                                            text-align: left;

                                            .icon {
                                                position: absolute;
                                                top: 50%;
                                                left: 50%;
                                                transform: translate(-50%, -50%);
                                                width: 15px;
                                                height: 15px;
                                                text-align: center;
                                            }
                                        }
                                    }

                                    &.home {
                                        .preview {
                                            background-color: #222;
                                            background-image: radial-gradient(ellipse farthest-corner at center, #0068a0 0, #002c8e 100%);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    #status_row {
                        height: $status_height; //border: 1px solid red;
                        flex: 1;
                        margin-right: 135px;
                        overflow: hidden;
                        flex-wrap: nowrap;
                        display: flex;

                        &>div {
                            border-right: 1px solid #333;
                            margin-right: 10px;
                            padding-right: 10px;

                            //overflow: hidden;
                            &:last-child {
                                border-right: none;
                            }
                        }

                        #links {
                            padding-right: 5px;
                            margin-right: 5px;
                            padding-left: 5px;
                            background-color: black;
                            border-radius: 5px;

                            .command {
                                padding: 1px;
                                color: $link_color_alt;
                                cursor: pointer;

                                &:hover {
                                    color: white;
                                }
                            }
                        }

                        .block {
                            cursor: pointer;
                            padding: 3px 4px;
                            margin: 2px;
                            color: #333;
                            height: 33px;
                            text-align: center;
                            border-radius: 5px;
                            background-color: #000;

                            &.OFFLINE {
                                color: red;
                            }

                            &.ONLINE {
                                color: white;
                            }

                            &.WARNING {
                                color: yellow;
                            }
                        }

                        .statcommand {
                            cursor: pointer;
                            width: 50px;
                            font-size: 30px;
                            color: $link_color_alt;
                            cursor: pointer;

                            &:hover {
                                color: white;
                            }
                        }

                        #qr {
                            cursor: pointer; //background-color: black;
                            text-align: center;

                            .text {
                                display: none;
                            }

                            svg {
                                width: 35px;
                                height: 43px;

                                path {
                                    fill: white;
                                }
                            }

                            &.zoomed {
                                background-color: white;
                                color: black;
                                position: fixed;
                                width: 340px;
                                height: 340px;
                                left: 50%;
                                top: 50%;
                                margin-left: -170px;
                                margin-top: -170px;
                                border-radius: 10px;
                                padding: 20px;

                                .text {
                                    display: block;
                                    padding-bottom: 15px;
                                    padding-top: 15px;
                                }

                                svg {
                                    width: 250px;
                                    height: 250px;

                                    path {
                                        fill: black;
                                    }
                                }
                            }
                        }

                        #pollers {
                            &>div {
                                display: flex;
                            }

                            .poller {
                                font-size: 27px;
                                width: 35px;
                            }
                        }

                        #devices {
                            &>div {
                                display: flex;
                            }

                            .device {

                                /* border: 2px outset #999; */
                                .value {
                                    display: block;
                                    color: #999;

                                    .unit {
                                        color: #777;
                                    }
                                }

                                &.alarm {
                                    .value {
                                        color: red;
                                    }
                                }

                                &.warning {
                                    .value {
                                        color: yellow;
                                    }
                                }

                                &.stale {
                                    opacity: 0.5;
                                }

                                &.off {
                                    color: #888;

                                    .value {
                                        display: none;
                                    }

                                    .value {
                                        content: "off";
                                    }
                                }

                                #name,
                                #status {
                                    display: none;
                                }
                            }
                        }

                        // #version {
                        // 	text-align: right;
                        // 	font-weight: bold;
                        // 	text-overflow: ellipsis;
                        // 	overflow: hidden;
                        // 	line-height: 20px;
                        // }
                    }

                    #commands {
                        float: right;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: flex-start;
                        width: 115px;
                        height: 153px;
                        padding-right: 17px;

                        .command {
                            flex-grow: 1;
                            flex-basis: 90px;
                            text-align: center;
                            margin-left: 10px;
                            margin-right: 0;
                            margin-bottom: 6px;
                            border: 3px solid #0FF;
                            padding: 2px 10px 4px;
                            border-radius: 5px;
                            color: #aaa;
                            cursor: pointer;
                            background-color: #000;
                            white-space: nowrap;
                            height: 19px;
                            overflow: hidden;
                            // text-overflow: ellipsis;
                            min-width: 19px;
                            user-select: none;

                            &.half {
                                flex-basis: 15px;
                            }

                            &.disabled {
                                cursor: not-allowed;
                                pointer-events: none;
                                opacity: 0.4;
                            }

                            &:hover {
                                border-color: white;
                            }
                        }

                        #fullscreen {
                            width: 23px;
                            height: 32px;
                            flex: none;
                            font-size: 29px;
                            color: #0ff;
                            margin-left: 10px;
                            margin-right: 0;
                            margin-bottom: 6px;
                            border: 3px solid #0FF;
                            padding: 2px 10px 4px 8px;
                            border-radius: 5px;
                            background-color: #000;

                            &.active {
                                color: white
                            }
                        }

                    }

                    #commands::after {
                        content: "";
                        flex: auto;
                    }

                    @media screen and (max-width: 800px) {
                        #commands {
                            .command {
                                text-align: center;
                                width: 50px;
                                padding: 3px 4px;

                                &.half {
                                    flex-basis: 7px;
                                }

                                .label {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                // #MENU {
                // 	display: flex;
                // }
            }
        }
    }

    #TOOLTIP_BOX {
        $background: #111;

        #TOOLTIP {
            -webkit-user-select: none;
            -moz-user-select: none;
            box-shadow: 0 0 100px 50px rgba(0, 0, 0, 0.9);
            color: white;
            position: absolute;
            // min-width: 350px; // max-width: 600px;
            width: 600px;
            border: 4px solid $link_color;
            background-color: $background;
            padding: 10px;
            border-radius: 10px;

            &.fullscreen {
                left: 0 !important;
                right: 0 !important;
                bottom: 0 !important;
            }

            a {
                color: $link_color_alt;
            }

            h2 {
                text-align: center;
            }

            .smallnote {
                font-style: italic;
                padding: 10px;
                color: #777;
            }

            #description {
                color: #999;
                font-size: 13px;
                padding: 10px;

                &:empty {
                    display: none;
                }
            }
			
            #subscribers {
                .off {
                    opacity: 0.2
                }
				.subscriber {
					border: 3px solid #333; 
					border-radius: 7px;
					padding: 2px;
					margin: 2px;

					.states {
						display: flex;
						.state {
							padding: 2px;
							&.alarm  {
								color: red
							}
							&.warning  {
								color: yellow
							}
							&.normal  {
								color: green
							}
						}
					}
				}
			}

            .user_settings {
                width: 90%;
                background-color: #111;
                color: white;
                padding-left: 15px;
                margin-bottom: 10px;
                border: none;
                border-bottom: 1px solid #aaa;

                &:invalid {
                    border: 1px solid red;
                    background-color: white;
                }

                &:focus {
                    background-color: white;
                    color: black;
                }
            }

            .widget_links {
                display: flex;
                padding: 0px;
            }

            .widget_link {
                cursor: pointer;
                flex-grow: 1;
                text-align: center;
                color: $link_color_alt;
                margin: 10px;
            }

            .button {
                cursor: pointer;
                border: 2px solid $link_color_alt;
                margin: 5px;
                border-radius: 5px;
                text-align: center;
                background-color: black;
                width: auto;

                &.disabled {
                    opacity: 0.5;
                    pointer-events: none;
                }

                &:hover {
                    border-color: white;
                }

                &.active {
                    border-color: white;
                }
            }

            #alarm_state {
                border: 5px solid white;
                padding: 15px;
                margin-top: 10px;
                margin-bottom: 10px;
                font-size: 20px;
                border-radius: 15px; //display  : flex;

                #icon {
                    font-size: 30px;
                    padding-right: 20px;
                    float: left;
                    top: -8px;
                    position: relative;
                }

				&.info, 
                &.normal {
                    display: none;
                    border: none;
                }

                &.stale {
                    border-color: white;
                    background: repeating-linear-gradient(45deg, #111, #111 10px, #222 10px, #222 20px);
                }

                &.alarm,
                &.alarm_ack {
                    border-color: red;
                    border-width: 10px;
                    background: repeating-linear-gradient(45deg, #100, #100 10px, #200 10px, #200 20px); //	animation   : BP 1s ease infinite;
                }

                &.warning,
                &.warning_ack {
                    border-color: yellow;
                    padding: 20px;
                    background: repeating-linear-gradient(45deg, #110, #110 10px, #220 10px, #220 20px); //	animation   : BP 2s ease infinite;
                }

                &.alarm_ack,
                &.warning_ack {
                    border-style: dashed;
                }

                .ack {
                    font-size: 12px;
                    position: relative;
                    left: 20px;
                    top: 5px;
                    opacity: 0.7;
                }

                button#ack {
                    float: right;
                    position: relative;
                    top: -6px;
                }

                @keyframes BP {
                    0% {
                        transform: scale(0.9);
                    }

                    50% {
                        transform: scale(1);
                    }

                    100% {
                        transform: scale(0.9);
                    }
                }
            }

            table {
                width: 100%;

                th {
                    text-align: right;
                    padding-right: 10px;
                    max-width: 50%;
                }

                td {
                    color: #aaa;
                    white-space: nowrap;

                    &.alarm {
                        color: red;
                    }

                    &.warning {
                        color: yellow;
                    }
                }
            }

            #header {
                margin-bottom: 10px;
                padding-bottom: 5px;
                cursor: move;

                #devicelink {
                    position: absolute;
                    top: 0px;
                    right: 40px;
                    padding-top: 10px;
                    padding-right: 10px;
                }

                #close {
                    color: $link_color_alt;
                    // float: right;
                    width: 50px;
                    height: 20px;
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    padding-top: 10px;
                    padding-right: 10px;
                    text-align: right;
                    cursor: pointer;

                    &:hover {
                        color: white;
                    }
                }

                .name {
                    color: #aaa;
                    padding-left: 5px;
                }

                #commands {
                    margin-top: 5px;
                    margin-left: 3px;
                    margin-right: 3px;
                    display: flex;

                    .command {
                        padding-top: 3px;
                        padding-bottom: 3px;
                        flex-grow: 1;
                        color: $link_color_alt;
                        text-align: center;
                        cursor: pointer;
                        font-size: 12px;
                        border-top: 2px solid $background;
                        border-bottom: 2px solid $link_color;

                        &.disabled {
                            opacity: 0.5;
                            cursor: not-allowed;
                            pointer-events: none;
                        }

                        &:hover {
                            color: white;
                        }

                        &.active {
                            color: white;
                            border: 2px solid $link_color;
                            border-bottom: 2px solid $background;
                        }
                    }
                }
            }

            #footer {
                display: flex;
                border-top: 2px solid $link_color;
                margin-top: 10px;
                padding-top: 5px;

                .command {
                    flex-grow: 1;
                    padding: 5px;

                    &.disabled {
                        opacity: 0.5;
                        cursor: not-allowed;
                    }
                }
            }

            #content {
                box-sizing: border-box;
                max-height: 450px;
                overflow: auto;

                canvas {
                    background-color: black;
                }

                // &::-webkit-scrollbar {
                //     display: none;
                // }
                .link {
                    color: $link_color_alt;

                    &:hover {
                        color: white;
                    }
                }

                #device {
                    overflow: hidden;
                    height: 100%;

                    #prefix {
                        font-size: 35pt;
                        text-align: center;
                        width: 100px;
                        border-radius: 10px;
                        border: 3px solid #333;
                        color: white;

                        &.running {
                            background-color: #0F0;
                            color: black;
                        }

                        #running_state {
                            font-size: 12px;
                            opacity: 0.5;
                        }
                    }

                    #image {
                        font-size: 40px;

                        img {
                            height: 100%;
                        }
                    }

                    #events {
                        background-color: #000;
                        height: 200px;
                    }

                    #links {
                        text-align: center;
                    }
                }

                #asof {
                    top: 44px;
                    position: relative;
                    float: right;
                    background-color: #111;
                    padding: 5px;
                    font-size: 8pt;
                    z-index: 5;
                    right: 10px;
                    color: #666;
                }

                #device_library {
                    #devices {
                        display: flex;
                        border: 8px dashed #999;
                        margin: 10px;
                        padding: 30px;
                        border-radius: 30px;

                        .device {
                            flex-grow: 1;
                            border: 1px solid #333;
                            padding: 10px;
                            margin: 5px;
                            text-align: center;
                        }
                    }
                }

                #connections {
                    #map {
                        table {
                            border-spacing: 0;

                            td {
                                //border: 1px solid #333;
                                width: 12.5%;
                                height: 15px;
                            }
                        }

                        .box {
                            border: 2px solid white;
                            width: 25%;
                            text-align: center;
                            border-radius: 20px;
                            cursor: pointer;

                            &:hover {
                                border-color: $link_color_alt;
                            }
                        }

                        .bbottom {
                            border-bottom: 2px solid white;
                        }

                        .bleft {
                            border-left: 2px solid white;
                        }

                        .bright {
                            border-right: 2px solid white;
                        }

                        .alarm {
                            border-color: red;
                        }

                        .warning {
                            border-color: yellow;
                        }

                        .none {
                            border-color: rgba(0, 0, 0, 0);
                        }
                    }
                }

                #data {
                    .reason {
                        text-align: center;
                    }

                    .value {
                        text-align: right;
                        padding-right: 10px;
                    }
                }

                #events {
                    // #event_list {
                    overflow-x: hidden;
                    overflow-y: auto;
                    flex: 1;
                    text-align: top;
                    color: white;
                    box-sizing: border-box;

                    .table {
                        width: 100%;
                        display: table;
                    }

                    .tr {
                        display: table-row;
                        padding-bottom: 5px;
                    }

                    .td {
                        vertical-align: top;
                        display: table-cell;
                        padding: 2px;
                    }

                    .td {
                        .name {
                            color: white;
                            cursor: pointer;
                        }

                        .alarm {
                            color: red;
                        }

                        .warning {
                            color: yellow;
                        }
						.info, 
                        .normal {
                            color: green;
                        }

                        .off {
                            color: #555;
                        }
                    }

                    .tr.alarm {
                        color: red;
                    }

                    .tr.warning {
                        color: yellow;
                    }

					.tr.info,
                    .tr.normal {
                        color: #0f0;
                    }

                    .event {
                        color: #999;
                    }

                    // }
                }

                #string {
                    padding: 15px;
                    text-align: center;
                    font-family: monospace;
                    font-size: 20px;
                }

                #boolean {
                    #value {
                        display: flex;

                        div {
                            flex-grow: 1;
                            padding: 10px;
                            margin: 5px;
                            font-family: monospace;
                            font-size: 20px;
                            text-align: center;
                            border: 5px outset #999;

							&.active.info,
                            &.active.normal {
                                background-color: green;
                            }

                            &.active.warning {
                                background-color: yellow;
                                color: black;
                            }

                            &.active.alarm {
                                background-color: red;
                            }
                        }
                    }
                }

                #stats,
                #number,
                #boolean,
                #totalizer {
                    th {
                        .tooltiptext {
                            visibility: hidden;
                            width: 120px;
                            background-color: #111;
                            color: #fff;
                            text-align: center;
                            padding: 5px 0;
                            border-radius: 6px;
                            position: absolute;
                            z-index: 1;
                            text-align: left;
                            font-weight: normal;
                            font-size: 12px;
                            opacity: 0.9;
                            padding: 5px;
                            bordeR: 1px solid #069;
                            box-shadow: 0px 0px 10px 10px #000;
                        }

                        &:hover .tooltiptext {
                            visibility: visible;
                        }
                    }
                }

                #number,
                #totalizer {
                    .unit {
                        opacity: 0.5;
                    }

                    #value {
                        background-color: black;
                        box-sizing: border-box;
                        width: 100%;
                        text-align: center;
                        border: 3px inset #999;
                        color: green;
                        font-family: monospace;
                        padding: 10px 10px 15px;
                        margin: 5px 0;
                        font-size: 50px;
                        border-radius: 10px;
                        overflow: hidden;

                        .unit {
                            opacity: 0.5;
                        }

                        #unitselector {
                            border: 1px solid #555;
                            padding: 5px;
                            border-radius: 5px;
                            font-size: 16px;
                            float: right;
                            text-align: left;
                        }

                        .meter_label {
                            font-size: 10px;
                            color: #999;
                        }

                        #meter {
                            width: 100%;
                            height: 50px;
                            display: table;
                            font-size: 1px; //border       : 1px solid #fff;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
    }

    div.overlay {
        position: absolute;
        display: block; // overflow: hidden;
        z-index: 10;
        color: rgba(0, 0, 0, 0.0); //		outline: 3px dashed red;
        outline: 4px dashed rgba(0, 95, 143, 0.0);
        outline-offset: 5px;

        &:hover {
            outline: 4px dashed rgba(0, 95, 143, 0.6);
        }

        &.exposed {
            outline: 4px solid #333;
        }

        &.selected {
            outline: 4px dashed rgba(0, 95, 143, 1);
        }

        .node {
            width: 11px;
            height: 11px;
            border-radius: 10px;
            border: 2px solid rgba(0, 100, 150, 0.5);
            position: absolute;
            transform: translate(-7.5px, -7.5px);
            cursor: all-scroll;

            &.hover,
            &:hover {
                border-color: white;
                z-index: 20;
                background-color: rgba(0, 0, 0, 0.5);
            }

            &.N {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                padding-top: 10px;
                transform: translate(-7.5px, -17.5px);
            }

            &.S {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                padding-bottom: 10px;
            }

            &.W {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                padding-left: 10px;
                transform: translate(-17.5px, -7.5px);
            }

            &.E {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                padding-right: 10px;
            }
        }
    }

    .widget {
        position: absolute;
        overflow: hidden;
        border: 1px solid rgba(0, 0, 0, 0);
        -ms-overflow-style: none; // IE 10+
        overflow: -moz-scrollbars-none; // Firefox

        &::-webkit-scrollbar {
            display: none; // Safari and Chrome
        }

        &.passthru {
            pointer-events: none;
        }

        &.stale,
        .stale {
            opacity: 0.3;
        }

        &.loading {

            //opacity: 0.4;
            &:before {
                content: '';
                box-sizing: border-box;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 40px;
                height: 40px;
                margin-top: -20px;
                margin-left: -20px;
                border-radius: 50%;
                border: 5px solid #444;
                border-top-color: $link_color;
                animation: spinner 0.6s linear infinite;
                box-sizing: border-box;
            }
        }

        @keyframes spinner {
            to {
                transform: rotate(360deg);
            }
        }

        &.edit {
            border: 3px solid rgba(0, 255, 255, 0.1);
            cursor: context-menu;
        }

        &.empty {
            outline: 2px dashed red;
            outline-offset: 5px;
        }
    }
}

.PAGE {

    #event_list,
    #event_log_box {
        width: 100%;
        display: table;
        color: green;

        .tbody {
            display: table-row-group;
        }

        .row {
            display: table-row;
        }

        .tr {
            display: table-row;

            .td {
                display: table-cell;
                padding: 5px;
            }
        }
    }
}

#ALARM_LIST {
    table {
        padding: 10px;
        width: 100%;
        background-color: #000;
    }

    .row.value {
        font-family: monospace;
        font-size: 18px;

        .unit {
            color: #aaa;
            padding-left: 5px;
        }
    }

    .row.cmd {
        text-align: center;
    }

    .tbody {
        display: table-row-group;
    }

    .row {
        display: table-row;

        .tag_name {
            white-space: nowrap;
        }
    }

    .row:hover {
        background-color: #222;
    }

    .row.alarm {
        .tag_name {
            color: red;
        }
    }

    .row.warning {
        .tag_name {
            color: yellow;
        }
    }

    .row.alarm_ack {
        .tag_name {
            color: #a00;
        }
    }

    .row.warning_ack {
        .tag_name {
            color: #aa0;
        }
    }
}

#LOGIN {
    color: white;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: #023;

    .right {
        text-align: right;
    }

    .panel {

        //border: 1px solid red;
        &#header {
            text-align: center;
            padding: 20px;
            max-height: 35%;

            img {
                max-width: 75%;
                max-height: 80%;
            }
        }

        &#userlist_box {
            overflow-x: hidden;
            overflow-y: auto;
            flex: 1;
            padding: 20px;
            background-color: #000;
        }
    }

    #message {
        color: red;
        text-align: center;
        padding: 10px;
        font-size: 25px;
    }

    #userlist_box {
        #USERS {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 30px;

            .user {
                border: 4px solid #555;
                border-radius: 10px;
                padding: 5px;
                margin: 5px;
                text-align: center;
                background-color: #222;
                cursor: pointer;
                flex-grow: 1;

                &:hover {
                    border-color: $link_color_alt;
                }

                &.disabled {
                    opacity: 0.5;
                    filter: grayscale(1.0);
                }

                &.selected {
                    border-color: white;
                }

                .labels {
                    .last_visit {
                        display: none;
                    }
                }

                #perms {
                    display: none;
                }

                .imgbox {
                    height: 170px;

                    img {
                        border-radius: 7px;
                        height: 150px;
                    }

                    i {
                        font-size: 150px;
                    }
                }
            }
        }
    }

    #footer {
        background-color: #023;
        box-shadow: 5px 10px 10px 10px #888888;
    }

    .flex {
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
        font-size: 25px;


        .small {
            font-size: 10px;
            text-align: center;
        }

        div {
            flex-grow: 1;
            padding: 5px;

        }

        input {
            width: 100%;
            font-size: 25px;
            box-sizing: border-box;

            &.btn {
                margin: 0px;
                font-size: 20px;
            }

            &.disabled {
                opacity: 0.5;
            }
        }
    }

}

#SPLASH {
    #box {
        color: white;
        position: absolute;
        left: 50px;
        top: 50px;
        right: 50px;
        bottom: 50px;
        text-align: center;

        #logo {
            height: 50%;
            max-width: 100%;
            vertical-align: middle;
            margin-bottom: 20px; //border        : 1px solid red;

            .helper {
                display: inline-block;
                height: 100%;
                vertical-align: middle;
            }

            img {
                // height        : 100%;
                max-width: 95%;
                max-height: 100%;
                vertical-align: middle; //border        : 1px solid red;
            }
        }

        #loading {
            width: 100%;
        }

        #log {
            margin-bottom: 20px;

            .progress_icon {
                display: inline-block;
                font-size: 15px;
				// border: 1px solid red;
                color: #aaa;
                $ease: cubic-bezier(0.785, 0.200, 0.735, 1.650);
                $time: 100ms;
                transition: transform $time $ease, opacity $time $ease;

                &.loading {
                    opacity: 0.1;
                    transform: scale(0.6);
                }

                &.error {
                    color: red;
                }
            }

            svg {
                color: white;
                margin: 20px;
                font-size: 50px; //			display  : flex;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    body {
        overflow-y: auto; //  how to scroll the login screen?

        .hide {
            display: none;
        }

        #LOGIN {

            //padding: 20px;
            p {
                text-align: center;
                font-size: 11px;
            }

            #header {
                text-align: left;
                padding: 20px;
                max-height: 20% !important;

                img {
                    max-width: 75%;
                    max-height: 50% !important;
                }
            }

            #userlist_box {
                #USERS {
                    .user {
                        font-size: 10px;

                        .imgbox {
                            height: 60px;
                            flex: 1 1 0;

                            img {
                                border-radius: 7px;
                                height: 40px;
                            }

                            i {
                                font-size: 40px;
                            }
                        }
                    }
                }
            }
        }
    }

    // #EXTRA_BOX {
    //     //font-size: 10px;
    //     // div {
    //     // 	padding: 3px !important;
    //     // }
    // }
    #SPLASH {
        #box {
            font-size: 11px;

            #logo {
                height: auto;

                img {
                    height: auto;
                    max-height: 150px;
                    max-width: 95%;
                }
            }

            #log {

                // .progress_icon {}
                i {
                    color: white;
                    margin: 10px;
                    font-size: 30px; //			display  : flex;
                }
            }
        }
    }
}

.PAGE {
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-height: 100vh;

    background-color: #011;
    color: white;

    // .panel {
    //     // position: absolute;
    //     // left: 0;
    //     // right: 0;
    // }
    .filter {
        float: right;
        border-radius: 5px;
        padding: 5px 10px;
        margin: 25px 15px;
        cursor: pointer;

        &.on {
            border: 4px inset #999;
            background-color: $lighter;
        }

        &.off {
            border: 4px outset #999;
            background-color: $darker;
        }
    }

    #notes {
        margin: 20px;
    }

    &>#header {
        // top: 0;
        //height: 85px;
        background-color: #033;
        padding-left: 20px;
    }

    &>#footer {
        background-color: #033;
        // bottom: 0;
        //height: 50px;
    }

    &>#page_box {
        flex: 1;
        overflow: auto;
        //        top: 85px;
        //       bottom: 50px;
        //      left: 10px;
        //     right: 10px;
        //   overflow: auto;
        // box-sizing: border-box;
    }

    .field {
        width: 90% !important;
    }

    a {
        color: $link_color_alt !important;
    }

    .link {
        color: $link_color_alt !important;
    }

    th {
        text-align: left; //  border-bottom: 2px solid #999;
    }

    .table {
        width: 100%;

        //td {}
        .icon_cell {
            color: white;
            font-size: 100px;
        }
    }

    .table-left {
        th {
            padding: 5px;
            background-color: #333;
        }

        td {
            color: #aaa;
        }
    }

    .table-top {
        th {
            text-align: right;
            background-color: #333;
            padding: 5px;
        }
    }

    .table_view {
        .model_name {
            font-weight: bold;
        }

        th {
            text-align: left;
        }

        td {
            cursor: pointer;
            color: #aaa;
        }
    }

    // h1 {}
    .flex {
        flex-wrap: wrap;

        &>div {
            flex-grow: 1;
            flex-basis: 0;
            white-space: nowrap;
            width: 33%;
            min-width: 33%;

            .label {
                font-weight: bold;
                float: left;
                background-color: #333;
                border-right: 2px solid #aaa;
                padding: 5px;
                width: 45%;
            }

            .value {
                float: right;
                padding: 5px;
                width: 50%;
            }
        }
    }
}

// svg {
//     -webkit-filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.5));
//     filter: drop-shadow(12px 12px 7px rgba(0, 255, 0, 0.5));
// }