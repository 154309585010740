#DT {
    #USERS {
        $usersize: 150;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .user {
            //			flex-grow: 1;
            flex-basis: 20%;
            border-radius: #{calc($usersize / 3)}px;
            text-align: center;
            padding: 30px;
            margin: 10px;
            cursor: pointer;
            border: 5px solid #eee;
            .last_visit {
                color: #777;
            }
            #perms {
                margin-top: 10px;
                align-items: center;
                justify-content: center;
                display: flex;
                .perm {
                    //border: 1px solid black;
                    margin: 2px;
                    width: 19px;
                    border-radius: 5px;
                    padding: 5px;
                    color: black;
                    &.disabled {
                        opacity: 0.2;
                    }
                    &.enabled {}
                }
            }
            .headshot {
                i,svg {
                    font-size: 100px;
                }
                img {
                    height: 100px;
                    border-radius: 15px;
                }
            }
        }
    }
}

.PAGE#USERS {
    .notification {
        &>i {
            font-size: 60px;
        }
        text-align: center;
        .fontawesomechooser {
            display: inline-block;
            float: none;
            width: 80px;
            .preview {
                font-size: 50px;
                padding: 0px; // i {
                //     font-size: 60px;
                // }
            }
        }
    }
}
