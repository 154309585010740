& {
    &.flex {
        display: flex;
        flex-direction: column;
    }

    #list {
        flex-grow: 1;
        overflow-x: hidden;
        overflow-y: auto;
        flex: 1;
        text-align: top;
        color: white;
        box-sizing: border-box;
        height: 100%;

        .table {
            width: 100%;
            display: table;
        }

        .tr {
            display: table-row;
            padding-bottom: 5px;
        }

        .td {
            vertical-align: top;
            display: table-cell;
            padding: 2px;
        }

        .td {
            .name {
                color: white;
                cursor: pointer;
            }

            .alarm {
                color: red;
            }

            .warning {
                color: yellow;
            }

            .normal {
                color: green;
            }

            .off {
                color: #555;
            }
        }

        .tr.alarm {
            color: red;
        }

        .tr.warning {
            color: yellow;
        }

        .tr.normal {
            color: #0f0;
        }

        .event {
            color: #999;
            display: block;
        }
    }

    .pill {
        float: left;
        padding: 5px;
        color: #eee;
        border-radius: 5px;
        transform: scale(1);
        display: none;
        transition: 5s linear;
        margin: 3px; //transition: transform 500ms cubic-bezier(0.23, 1, 0.32, 1), opacity 500ms cubic-bezier(0.23, 1, 0.32, 1);
        white-space: nowrap;

        &.alarm {
            display: inline;
            border: 3px solid red; //transform:scale(2);
            background-color: rgba(255, 0, 0, 0.2);
        }

        &.alarm_ack {
            color: #aaa;
            display: inline;
            border: 3px dashed red; //transform:scale(2);
            background-color: rgba(255, 0, 0, 0.2);
        }

        &.warning {
            display: inline; //transform:scale(2);
            border: 3px solid rgb(255, 255, 0);
            background-color: rgba(255, 255, 0, 0.2);
        }

        &.warning_ack {
            color: #aaa;
            display: inline;
            border: 3px dashed rgb(255, 255, 0); //transform:scale(2);
            background-color: rgba(255, 255, 0, 0.2);
        }

        &.normal {}

        &.flash {
            background-color: rgba(255, 255, 255, 0.5);
        }

        .name {
            padding-right: 5px;
        }
    }
}
