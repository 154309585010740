& {
    $width: 18px;
    $height: 18px;
    cursor: pointer;
    #ring {
        border: 3px solid $link_color_alt;
        border-radius: calc($height / 3);
        width: $width;
        height: $height;
    }
    #slider {
        background-color: rgbaa(0,0,0,0);
        border-radius: 3px;
        width: $height - 5;
        height: $height - 5;
        transform: translate(2.5px, 2.5px);
        transition: 200ms ease-out;
        &.on {
            background-color: $link_color_alt;
        }
    }
    #label {
        position: absolute;
        left: $width+10;
        top: 5px;
    }
}
