#DT {
	#DETAILS {
		#NETWORK {
			& > #icon {
				text-align: center;
				font-size: 150px;
				padding: 20px;
				text-shadow: 2px 2px 1px #fff;
			}

			#preview {
				height: 450px;
				background-color: #bbb;
			}
		}
	}

	#CONTENT {
		#NETWORK {
			.network {
				text-align: center;
				flex-basis: 20%;
				//border: 1px solid red;
				height: 80px;
				width: 270px;
				border-radius: 20px;
				border: 2px solid black;
				margin: 20px;
				padding: 10px;
				float: left;

				&.offline {
					opacity: 0.2;
				}

				div {
					//border: 1px solid #333;
				}

				#icon {
					font-size: 50px;
					float: left;
					width: 60px;
				}

				#status {
					text-align: left;
					clear: both;

					.status {
						font-weight: bold;
					}
				}

				#details {
					float: left;

					& > div {
						width: 200px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					.vendor {
						font-weight: bold;
					}

					.mac {
						color: #999;
					}
				}
			}
		}
	}
}
