& {
    #widget_box {
        display: flex;
        //flex-wrap: wrap;
        align-items: stretch;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        //border: 1px solid red;
        margin-bottom: 10px;
        overflow: hidden;

        // &.spread {
        // 	justify-content: space-around;
        // 	align-content: center;
        // }
        .widget {
            flex-grow: 1;
            flex-basis: 20%;
            width: auto !important;
            height: auto !important;
            left: 0 !important;
            top: 0 !important;
            position: relative;
            min-height: 25px;
            padding: 0px 10px;

            // .icon {
            //     width: 25px;
            // }

            // &:hover {
            //     z-index: 100;
            // }

            // &.current {
            //     background: white;
            //     text-shadow: 2px 3px 5px #999;
            //     border: 3px solid $link_color_alt;
            // }
        }
    }
}
