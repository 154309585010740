#LINK {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	#header {
		overflow :hidden;
		border-bottom: 3px dashed #0ff;
	}

	#body {
		flex-grow: 1;
		overflow: auto;
		iframe {
			border: none;
			background-color: white;
			width: 100%;
			height: 100%;
		}
	}
	
	#footer {
		border-top: 3px dashed #0ff;
		overflow: hidden;
	}
}
