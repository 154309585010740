& {
  overflow: auto;

  //transition:  5s linear;
  .arrow {
    display: inline-block;
    transition: all 250ms linear;
    color: #999;
  }

  &.slideup {
    #table {
      position: relative;
      top: -27px; // OFFSET so the column headers are above the title bar line
    }
  }

  td,
  tr {
    &:hover {
      background-color: #111;
    }

    &.hilight {
      background-color: #222;
    }

    &.alarm_ack {
      text-decoration: underline;
      text-decoration-color: $alarm_color;
      text-decoration-style: dashed; // wavy
      text-decoration-skip-ink: auto;
    }

    &.alarm {
      color: $alarm_color;
    }

    &.warning_ack {
      text-decoration: underline;
      text-decoration-color: $warning_color;
      text-decoration-style: dashed; // wavy
      text-decoration-skip-ink: auto;
    }

    &.warning {
      color: $warning_color;
    }

    &.off {
      opacity: 0.5;
    }
    &.zero {
      opacity: 0.5;
      //   color: red;
    }
  }

  td {
    text-align: right;
    font-family: monospace;
    padding: 2px;
    background-color: rgba(0, 0, 0, 0);
    transition: color 1s linear;

    &.flash {
      transition: color 0s linear;
      color: rgba(255, 255, 255, 1);
    }
  }

  th {
    text-align: left;
    color: #bbb;
    padding: 2px;
    white-space: nowrap;

    &.right {
      text-align: right;
    }
  }

  .unit {
    width: 15px;
  }
}
