& {
    .symbol {
        font-family: monospace;
        font-size: 9px;
        font-weight: bold;
        color: black;
        border-radius: 15px;
        padding: 3px;
        width: 25px;
        text-align: center;
        &.overdue {
            animation: task_blinker 0.75s linear infinite;
        }
        &.soon {
            animation: task_blinker 3s linear infinite;
        }
        @keyframes task_blinker {
            50% {
                transform: scale(1.5);
            }
        }
    }
    #schedule {
        border-bottom: 1px solid white;
        #schedulebox {
            width: 100%;
            margin-bottom: 5px;
            .symbol {
                position: relative;
                cursor: pointer;
            }
            td {
                border-top: 1px solid #999;
                border-right: 1px dashed #333;
            }
            th {
                width: 100px;
                background-color: #111;
                padding: 5px;
                &.hourscol{
                text-align: right;
                border-right: 1px solid #555;
                }
                &.device {
                    // /border-right: 1px solid #999;
                    text-align: left;
                    border-top: none;
                    width: 100px;
                }
            }
        }
    }
    #footer {
        button {
            padding: 10px;
            border-radius: 5px;
        }
        .click {
            color: #0FF;
        }
        #legend {
            color: #999;
            font-size: 10px;
            width: 100%;
            .symbolcol {
                text-align: center;
            }
        }
        tr.header td {
            background-color: #111;
            padding: 5px;
        }
        .notice {
            color: yellow;
        }
    }
}
