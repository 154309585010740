& {
    #cam {
        text-align: center;
    }

    #cam>img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 10px;
        cursor: pointer;

        width: 100%;
    }
}
