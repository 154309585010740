&.widget {
    white-space: nowrap;
    font-family: monospace;

    &.alarm {
        .val {
            color: red !important;
        }
    }

    &.warning {
        .val {
            color: yellow !important;
        }
    }

    &.off {
        .val {
            color: #333 !important;
        }
    }

    &.fancy {
        .image {
            float: left;
            padding-right: 10px;
            font-size: 165%;
            height: 100%;

            img {
                height: 100%;
            }
        }

        .icon {
            padding-top: 5px;
            float: right;
        }

        .name {
            color: rgba(255, 255, 255, 0.5);
            font-family: "Lucida Grande", Lucida, Verdana, sans-serif;
            font-size: 93%;
        }

        .val {
            color: rgba(255, 255, 255, 0.8);
            font-size: 120%;

        }

        .delta {
            float: right;
            padding-top: 5px;
            font-size: 90%;

            i {
                padding-left: 5px;
                margin-top: -2px;
            }

            &.rising {
                color: green;
            }

            &.falling {
                color: red;
            }
        }

        .description {
            color: rgba(255, 255, 255, 0.4);
            font-size: 80%;
            clear: both;
        }
    }

}