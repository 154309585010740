#DT {
	#POLLERS {
		#pollers {
			display: flex;
			flex-wrap: wrap;

			.protocol {
				border-bottom: 1px solid #999;
			}

			.poller {
				cursor: pointer;
				background-color: #eee;
				margin: 15px;
				flex-grow: 1;
				border: 2px solid black;
				border-radius: 3px;

				.icon {
					font-size: 50px;
				}

				#devices {
					display: flex;
					padding: 5px;
					flex-wrap: wrap;
					border-top: 2px solid black;

					.device {
						text-align: center;
						margin: 5px;
						padding: 5px;
						border-radius: 5px;
						float: left;
						flex-grow: 1;
						//background-color: $darker;
						.value {
							color: #999;
						}
					}

					.online {
						border: 2px solid green;
					}

					.offline {
						border: 2px solid red;
					}
				}

				.ONLINE {
					background-color: #efe;
					border: 1px solid #efe;
				}

				.WARNING {
					background-color: #ffe;
					border: 1px solid yellow;
				}

				.OFFLINE {
					background-color: #fee;
					border: 1px solid red;
				}

				table {
					padding: 10px;
					width: 100%;
					min-height: 66px;

					.name {
						font-weight: bold;
					}

					.state {
						text-align: center;
						//border: 5px outset #999;
						width: 150px;
						//border-radius: 8px;
						padding: 5px;
						background-color :#333;
						color:#07ff07;
						font-weight: bold;
						position: relative;
						top: 5px;
						font-family: monospace;
					}

					.status {
						font-style: italic;
						padding-left: 20px;
						padding-top: 10px;
						color: #999;
					}

					.button {
						text-align: right;
					}
				}
			}
		}
	}
}
