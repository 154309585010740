#DT {
	#SETTINGS {
		box-sizing: border-box;
		width: 100%;
		//padding: 100px 100px 100px 75px;

		caption {
			font-weight: bold;
		}

		thead {
			th {
				font-size: 22px;
				border-bottom: 1px solid black;
			}
		}
		.field {
			font-size: 20px;
		}

		table {
			font-size: 20px;
			width: 100%;
			padding-top: 50px;
			padding-right: 70px;
			padding-left: 70px;

			// tr:hover {
			// 	background-color: #eee;
			// }

			th {
				text-align: left;
				padding: 10px;
			}

			td {
				text-align: right;
				padding: 10px;

				input,select {
					width:100%;
				}

				&.description {
					padding-bottom: 50px;
					color: #555;
					font-size: 12px;
					padding-left: 10px;
					text-align: left;
				}
			}
		}
	}
}
