#DT {
	#DETAILS {
		#FILES {
			#preview {
				height: 450px;
				background-color: #bbb;
			}

			#content {
				font-size: 8px;
				border: 1px inset #999;
				padding: 5px;
				background-color: white;

				h1 {
					font-weight: bold;
					background-color: inherit;
					color: black;
				}

				h2 {
					font-weight: bold;
					background-color: inherit;
					color: black;
					border-bottom: none;
				}
			}
		}
	}

	#CONTENT {
		#FILES {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;

			.file {
				text-align: center;
				flex-basis: 20%;
				//border: 1px solid red;
				height: 145px;
				border-radius: 20px;
				margin: 20px;

				#icon {
					font-size: 100px;
				}

				#name {}
			}
		}
	}
}
