#DT {
    #SCREENS {
        $screensize: 150;
        .screen {
            height: #{$screensize}px;
            text-align: center;
            padding: 30px;
            margin: 5px;
            cursor: pointer;
            float: left;
            border-radius: 10px;
            .preview {
                background-color: #111;
                background-image: radial-gradient(ellipse farthest-corner at center, #004880 0%, #06013B 100%);
                color: white;
                border-radius: 5px;
                margin-left: auto;
                margin-right: auto;
                flex-direction: column;
                justify-content: center; 
                position: relative;
                font-size: 0px;
                overflow: hidden;
                .widget {
                    background-color: rgba(255, 255, 255, 0.5);
                    position: absolute;
                    border-radius: 2px;
                    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.7);
                    font-size: 1.5vmin;
                    color: rgba(0, 0, 0, 0.5);
                    overflow: hidden;
                    text-align: left;
                    .icon {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 15px;
						height: 15px;
						text-align: center;
                    }
                }
            }
            &.home {
                .preview {
                    background-color: #222;
					background-image: radial-gradient(ellipse farthest-corner at center, #0068a0 0, #002c8e 100%);
					border: 5px outset #bbb;
                }
            }
			&.disabled {
				opacity: 0.5;
				filter: grayscale(100%);
				pointer-events: all;
			}
            &.master {
                .preview {
                    background-image: radial-gradient(ellipse farthest-corner at center, #000 0, #024 100%);
					background-color: #000;
					border: 5px inset #bbb;
                }
            }
        }
    }
}
