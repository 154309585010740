&.note {
	margin: 0;
	background: linear-gradient(90deg,#333, #555);
	border-right: 5px solid #aaa;
	padding: 5px;
	box-sizing: border-box;
}

&.title {
	color: white;
	font-size: 14px;
	border-bottom: 3px solid white;
}

&.heading {
	color: white;
	font-size: 20px;
}
&.plain {
	color: #aaa;
	font-size: 12px;
}
&.left {
	text-align: left;
}
&.center {
	text-align: center;
}
&.right {
	text-align: right;
}


&.underline {
	border-bottom: 3px solid white;
}
&.box {
	outline: 3px solid white;
}
&.group {
	fieldset {
		color: white;
		font-size: 14px;
		font-weight: bold;
		margin-top: 0;
		pointer-events: none;

		legend {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
}
