& {
    #form {
        th {
            white-space: nowrap;
        }

        font-size: 20pt;
        background-color: #333;
        padding: 10px;
        border-radius: 5px;

        input,
        textarea {
            font-size: 20pt;
        }


        .description {
            font-size: 18px;
            text-align: justify;
            margin-bottom: 10px;
        }
    }
}