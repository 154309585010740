#DT {
    #DETAILS {
        #DEVICES {
            #device_library_details #details_footer {
                display: none;
            }

        }

        .device_status div {
            color: #555;
            font-style: italic;
            height: 35px;
            max-height: 35px;
            overflow: hidden;
        }

        .modpoll {
            font-size: 12px;
            overflow: hidden;

            .icon {
                font-size: 14px;
            }

            td {
                text-align: left;
            }

            .modtile {
                // white-space: nowrap;
                width: 100%;
                border: 2px solid #eee;

                &.SUCCESS {
                    border: 2px solid #0A0;

                    .icon {
                        color: #0A0;
                    }
                }

                &.FAIL {
                    border: 2px solid #F00;

                    .icon {
                        color: #F00;
                    }
                }

                .status {
                    border-bottom: 1px solid #ccc;
                    text-indent: -8px;
                    padding-left: 17px;
                }

                .modcmd {
                    font-family: monospace;
                    font-size: 12px;
                    white-space: nowrap;
                    overflow: hidden;
                    width: 100%;

                    input {
                        text-align: left;
                    }
                }

                background-color: #eee;
                padding: 5px;
                margin-bottom: 5px;
            }
        }
    }

    #CONTENT {
        #DEVICES {
            box-sizing: border-box;
            padding: 0 10px;
            flex-grow: 1;

            #status {
                display: flex;
                background-color: #ccc;
                border: 2px inset #bbb;
                border-radius: 5px;
                padding: 5px;
                font-size: 80%;

                &:empty {
                    display: none;
                }

                #state {
                    width: 150px;
                }

                #progress {
                    flex-grow: 1;

                    progress {
                        width: 100%;
                        padding-bottom: 5px;
                    }
                }

                #next {
                    text-align: right;
                    width: 100px;
                }
            }

            .device {
                box-sizing: border-box;
                width: 100%;
                padding: 10px;
                margin-bottom: 10px;
                margin-top: 5px;
                overflow: hidden;
                background-color: #ddd;
                border-radius: 7px;
                border: 2px solid rgba(0, 0, 0, 0.5);
                float: left;
                flex: 1;

                &.offline {
                    border-color: red;
                }

                &.disabled {
                    opacity: 0.5;
                    pointer-events: all;
                }

                #image {
                    width: 50px;
                    height: 50px;
                    float: left;
                    overflow: hidden;
                    font-size: 40px;
                    mix-blend-mode: multiply; // make white background transparent

                    img {
                        width: 100%;
                    }
                }

                #tags {
                    margin-left: 60px;
                    background-color: white;
                    margin-bottom: 5px;
                    border-radius: 5px;
                    border: 2px solid rgba(0, 0, 0, 0.5);
                    padding: 5px;
                }

                #header {
                    margin-bottom: 5px;
                    background-color: rgba(0, 0, 0, 0);
                    color: black;
                    cursor: auto;

                    .link {
                        color: $link_color;
                        border-left: 1px solid $darker;

                        &:last-child {
                            border-left: none;
                        }

                        &:hover {
                            color: white;
                        }
                    }
                }

                .prefix {
                    font-weight: bold;
                }

                .connection_string {
                    color: #555;
                }

                .error {
                    .icon {
                        color: red;
                    }
                }

                .rightmenu {
                    float: right;
                    overflow: hidden;
                    white-space: nowrap;
                    width: 250px;

                    .menuitem {
                        float: right;
                        margin: 5px;
                        padding-right: 5px; //	border-right : 2px solid rgba(0,0,0,0.1);
                        overflow: hidden;
                    }

                    #online {
                        width: 10px;
                        height: 10px;
                        border-radius: 9px;
                        border: 2px outset #999;
                        margin-top: 8px;
                        float: right;

                        &.online {
                            background-color: #3f3;
                        }

                        &.offline {
                            background-color: red;
                            animation: redblink 1s;
                            animation-iteration-count: infinite;
                        }
                    }
                }
            }
        }
    }
}