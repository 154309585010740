@import 'config';

@media screen and (max-width: 1800px) {
    .minwidth1800 {
        display: none !important;
    }
}

@media screen and (max-width: 1600px) {
    .minwidth1600 {
        display: none !important;
    }
}

@media screen and (max-width: 1200px) {
    .minwidth1200 {
        display: none !important;
    }
}

@media screen and (min-width: 1200px) {
    .maxwidth1200 {
        display: none !important;
    }
}

@media screen and (max-width: 1000px) {
    .minwidth1000 {
        display: none !important;
    }
}

@media screen and (min-width: 1000px) {
    .maxwidth1000 {
        display: none !important;
    }
}

@media screen and (max-width: 800px) {
    .minwidth800 {
        display: none !important;
    }
}

@media screen and (min-width: 800px) {
    .maxwidth800 {
        display: none !important;
    }
}

@media screen and (max-width: 600px) {
    .minwidth600 {
        display: none !important;
    }
}

@media screen and (min-width: 600px) {
    .maxwidth600 {
        display: none !important;
    }
}

@keyframes redblink {
    from {
        background-color: #f00; //box-shadow: 0 0 3px 2px rgba(255,0,0,0.4);
    }

    50% {
        background-color: #f00; //box-shadow: 0 0 9px 5px rgba(255,0,0,0.7);
    }

    51% {
        background-color: #000;
    }

    to {
        background-color: #000;
    }
}

.click {
    cursor: pointer;
    pointer-events: auto;
    user-select: none;
}

.noclick {
    pointer-events: none;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.noselect {
    -webkit-user-select: none;
    /* Chrome all / Safari all */
    -moz-user-select: none;
    /* Firefox all */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    /* Likely future */
}

.hover:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.flex {
    display: flex;

    .cell {
        flex-basis: auto;
        flex-grow: 1;
    }
}

.btn {
    display: inline-block;
    margin: 10px;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #333;
    background-color: #Eff;
    border-color: #ccc;
    min-width: 200px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);

    &:hover {
        border: 1px solid black;
    }

    &.danger {
        border-color: red;

        &:hover {
            background-color: #fee;
        }
    }

    &.default {
        border-width: 3px;
    }

    &:disabled {
        opacity: 0.5;
        cursor: default;
    }

    &.btn-small {
        min-width: auto;
        margin: 0;
        padding: 0 10px;
    }

    &.btn-clear {}
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

.pull-left {
    float: left !important;
}

.pull-right {
    float: right !important;
}

.pull-center {
    text-align: center !important;
}


.pad10 {
    padding: 10px;
}

hr {
    border: 0;
    height: 2px;
    background: #333;
}

.link {
    cursor: pointer;
}

.nowrap {
    white-space: nowrap;
}

.bold {
    font-weight: bold;
}

input[disabled] {
    cursor: not-allowed;
}

.shadow_box_dark {
    background-color: rgba(255, 255, 255, 0.1);
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: inset 1px 2px 10px 2px rgba(0, 0, 0, 0.2);
}

.shadow_box_light {
    background-color: rgba(0, 0, 0, 0.1);
    border: 2px solid rgba(0, 0, 0, 0.4);
    box-shadow: inset 1px 2px 10px 2px rgba(0, 0, 0, 0.2);
}

#MAIN {
    .link {
        color: $link_color;
    }

    a {
        color: $link_color;
        text-decoration: none;
        pointer-events: auto;

        &:hover {
            color: $link_color_alt;
        }
    }
}

#HEADER {
    .link {
        color: $link_color_alt;
    }

    a {
        color: $link_color_alt;
        text-decoration: none;
    }
}

.height-fill {
    max-height: 100%;
}

.height-small {
    max-height: 30px;
}

.height-medium {
    max-height: 200px;
}

fieldset {
    border: 1px solid #aaa;
    border-radius: 10px;
    overflow: hidden;
}

// .note {
// 	background-color: #eee;
// 	background      : linear-gradient(90deg,#eee, #fff);
// 	border-left     : 5px solid #aaa;
// 	padding         : 10px;
// 	margin          : 5px 0;
// 	font-size       : 12px;
// 	border-radius   : 0;
// }
//
// .note:empty {
// 	display: none !important;
// }
.textarea-big {
    width: 100%;
    height: 500px;
}

.table {
    padding: 20px;
    margin: 0;

    input:not([type=radio]):not([type=checkbox]) {
        width: 100%;
    }

    textarea {
        width: 100%;
        height: 150px;
    }
}

.table-full {
    min-width: 50%;
    width: 100%;

    td {
        padding: 5px;
    }
}

.table-left {
    th {
        border-right: 2px solid #aaa;
        border-bottom: none;
        text-align: right;
        padding-right: 10px;
        white-space: nowrap;
    }

    td {
        padding-right: 5px;
        color: $text_light_color;
    }

    td:last-child {
        border-right: inherit;
    }
}

.table-top {
    th {
        padding-left: 6px;
        padding-right: 5px;
        white-space: nowrap;
    }

    thead th {
        text-align: left;
        border-bottom: 2px solid #aaa;
        border-right: none;
    }

    tfoot th {
        border-top: 2px solid #aaa;
        border-bottom: none;
    }

    td {
        border-bottom: 1px solid #eee;
        vertical-align: top;
        color: $text_light_color;
    }

    tr:last-child td {
        border-bottom: inherit;
    }
}

.table-borders {
    td {
        border: 1px solid #ddd;
    }

    td:last-child {
        border: 1px solid #ddd;
    }
}

.table-full.table-left {
    min-width: 50%;

    th {
        min-width: 15%;
        max-width: 50%; // why no work?!
    }

    th.header_wide {
        padding-left: 6px;
        border-bottom: 2px solid #aaa;
        border-right: none;
        text-align: left;
    }
}

.table {
    th {
        &.right {
            text-align: right;
        }

        &.center {
            text-align: center;
        }

        &.important {
            background-color: rgba(0, 0, 0, 0.03);
        }
    }

    td {
        &.nowrap {
            white-space: nowrap;
        }

        &.right {
            text-align: right;
        }

        &.center {
            text-align: center;
        }

        &.important {
            background-color: rgba(0, 0, 0, 0.03);
            color: black;
        }
    }
}

.table-small {
    font-size: 10px;
    padding: 2px;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

progress[value]::-webkit-progress-bar {
    background-color: #eee;
    border-radius: 12px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

.round-small {
    border-radius: 5px;
}

.round-large {
    border-radius: 15px;
}

@keyframes expandWidth {
    0% {
        width: 0;
    }

    100% {
        width: auto;
    }
}

.widget {
    .meter {
        min-width: 50px;
        height: 8px;
        position: relative;
        border: 2px solid $text-light-color;
        padding: 2px;
        border-radius: 3px;
        box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);

        &.deviation {
            background: linear-gradient(to right, rgba(0, 0, 0, 0) 48%, #999 50%, rgba(0, 0, 0, 0) 52%);
            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        }

        &>.spot {
            border-radius: 3px;
            display: block;
            height: 100%;
            background-color: #0d0;
            position: relative;
            overflow: hidden;
            transition: left 2s;
            width: 8px;
            transform: translateX(-4px);
        }

        &>.bar {
            border-radius: 1px;
            display: block;
            height: 100%;
            //background-color: $text-color;
            position: relative;
            overflow: hidden;
            transition: width 2s;
        }

        // &>span.normal {
        //     background-color: green;
        //     background-image: linear-gradient(to bottom, #00ff00, #009900);
        // }

        // &>span.warning {
        //     background-color: #f1a165;
        //     background-image: linear-gradient(to bottom, #f1f165, #f3fd0a);
        // }

        // &>span.alarm {
        //     background-color: #f0a3a3;
        //     background-image: linear-gradient(to bottom, #f0a3a3, #f42323);
        // }
    }
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    margin: 8.1px 0;

    &:focus {
        outline: none;
    }

    &::-webkit-slider-runnable-track {
        width: 100%;
        height: 4px;
        cursor: pointer;
        background: $link_color_alt;
        border-radius: 1.3px;
    }

    &::-webkit-slider-thumb {
        border: 6px solid #000000;
        height: 20px;
        width: 30px;
        border-radius: 31px;
        background: $link_color_alt;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -8.3px;
    }

    &:focus::-webkit-slider-runnable-track {
        background: white;
    }

    &::-moz-range-track {
        width: 100%;
        height: 3.8px;
        cursor: pointer;
        box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
        background: #2c71a9;
        border-radius: 1.3px;
        border: 0.2px solid #010101;
    }

    &::-moz-range-thumb {
        box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
        border: 1px solid #000000;
        height: 20px;
        width: 19px;
        border-radius: 31px;
        background: #b7deff;
        cursor: pointer;
    }

    &::-ms-track {
        width: 100%;
        height: 3.8px;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }

    &::-ms-fill-lower {
        background: #245b89;
        border: 0.2px solid #010101;
        border-radius: 2.6px;
        box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
    }

    &::-ms-fill-upper {
        background: #2c71a9;
        border: 0.2px solid #010101;
        border-radius: 2.6px;
        box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
    }

    &::-ms-thumb {
        box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
        border: 1px solid #000000;
        height: 20px;
        width: 19px;
        border-radius: 31px;
        background: #b7deff;
        cursor: pointer;
        height: 3.8px;
    }

    &:focus::-ms-fill-lower {
        background: #2c71a9;
    }

    &:focus::-ms-fill-upper {
        background: #3487c9;
    }
}