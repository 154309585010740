& {
	$width: 38px;
	$height: 18px;
	cursor: pointer;

	#ring {
		border: 3px solid $link_color_alt;
		border-radius: $height;
		width: $width;
		height: $height;
	}

	#slider {
		background-color: $link_color_alt;
		border-radius: $height - 5;
		width: $height - 5;
		height: $height - 5;
		transform: translate(2.5px,2.5px);
		transition: 200ms ease-out;

		&.on {
			transform: translate(22px,2.5px);
		}
	}

	#label {
		position: absolute;
		left: $width+10;
		top: 5px;
	}
}
