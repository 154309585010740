& {
    font-size: 20px;
    #dash {
        display: flex;
        .light {
            padding: 5px;
            flex-grow: 1;
            text-align: center;
            color: #555;
            transition: all 1s cubic-bezier(.89, .53, .51, 1.42);
            text-shadow: 0px 0px 5px #ccc;
            &.alarm {
                transform: scale(1.5);
                color: red;
            }
            &.yellow {
                transform: scale(1.5);
                color: yellow;
            }
        }
    }
}
