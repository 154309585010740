$font_size_title: 14px;
$font_size_default: 12px;

#MAIN {
    #screen>.widget {
        $alarm_color: red;
        $warning_color: yellow;
        $normal_color: #00FF00;
        color: #aaa;
        font-size: $font_size_default;
        user-select: none; //	background-color: rgba(0,0,0,0.8);

        .title {
            border-bottom: 2px solid white;
            color: white;
            font-size: $font_size_title;
            padding-bottom: 5px;
            margin-bottom: 5px;
            font-weight: bold;
            text-align: left;

            &:empty {
                display: none;
            }

            &:blank {
                border-bottom: none;
            }

            &:empty {
                border-bottom: none;
            }
        }

        .unit {
            opacity: 0.6;
            padding: 0 !important;
            text-align: left !important;
        }

        &.DarkSky {
            border-radius: 10px;
        }

        &.Label {
            @import "../widgets/Label/style.scss";
        }

        &.Image {
            @import "../widgets/Image/style.scss";
        }

        &.Table {
            @import "../widgets/Table/table.scss";
        }

        &.Webcam {
            @import "../widgets/Webcam/style.scss";
        }

        &.OpLog {
            @import "../widgets/OpLog/oplog.scss";
        }

        &.Annunciator {
            #grid {
                display: flex;
                flex-wrap: wrap;

                &>div {
                    flex-grow: 1;
                    flex-basis: 60px;
                    white-space: nowrap;
                    border: 4px outset #aaa;
                    border-radius: 3px;
                    padding: 4px;
                    margin: 2px;
                    text-align: center;
                    transition: background-color 200ms linear;

                    &.alarm {
                        background-color: $alarm_color;
                        color: black;
                    }

                    &.alarm_ack {
                        color: $alarm_color;
                        border-color: $alarm_color;
                    }

                    &.warning {
                        background-color: $warning_color;
                        color: black;
                    }

                    &.warning_ack {
                        color: $warning_color;
                        border-color: $warning_color;
                    }

                    &.off {
                        opacity: 0.5;
                    }

                    &.normal {
                        background-color: $normal_color;
                        color: black;
                    }
                }
            }
        }

        &.Grid {
            #grid {
                display: flex;
                flex-wrap: wrap;

                &>div {
                    flex-grow: 1;
                    border: 1px solid #aaa;
                    padding: 4px;
                    margin: 1px;
                    text-align: center;

                    &.alarm {
                        color: $alarm_color;
                    }

                    &.warning {
                        color: $warning_color;
                    }

                    &.off {
                        opacity: 0.5;
                    }
                }
            }
        }

        &.Screen {
            outline: 2px solid $link_color_alt;
        }

        &.Value {
            @import "../widgets/Value/style.scss";
        }

        &.Button {
            @import "../widgets/Button/style.scss";
        }

        &.Days {
            @import "../widgets/Days/style.scss";
        }

        &.Group {
            @import "../widgets/Group/style.scss";
        }

        &.Menu {
            .Button {
                @import "../widgets/Button/style.scss";
            }

            @import "../widgets/Menu/style.scss";
        }

        &.Newsfeed {
            @import "../widgets/Newsfeed/newsfeed.scss";
        }

        &.Toggle {
            @import "../widgets/Toggle/style.scss";
        }

        &.Checkbox {
            @import "../widgets/Checkbox/style.scss";
        }

        &.Keypad {
            @import "../widgets/Keypad/style.scss";
        }

        &.List {
            @import "../widgets/List/style.scss";
        }

        &.Maintenance {
            @import "../widgets/Maintenance/style.scss";
        }

        &.DashLights {
            @import "../widgets/DashLights/style.scss";
        }
    }
}