&,
.widget .Button {
    width: 100%;
    height: 100%;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:active,
    &:focus {
        background-color: white;
        outline: none;
    }

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
        cursor: not-allowed;
        border: none !important;
    }

    &.transparent {
        background-color: rgba(0, 0, 0, 0);
        color: rgba(0, 0, 0, 0);
        border: none;

        &:hover {
            border: 3px solid $link_color_alt;
        }

    }

    &.default {
        border-radius: 15px;
        color: #494949;
        text-shadow: 0 1px 5px #fff;
        font-size: 20px;
        font-weight: bold;
        border: 1px solid rgba(0, 0, 0, 0.67);
        min-height: 25px;
        padding: 0 10px;
        background: linear-gradient(to bottom, #c3c3c3 2.9%, #bbb 40.6%, #c9c9c9 55.8%, #c7c7c7 100%) #bbbbbb;

        .icon {
            width: 25px;
        }

        &:hover {
            //border: 5px dashed $link_color_alt;
            // transition: transform 1000ms linear;
            // transform: scale(1.1);
            z-index: 100;
            // color: #069;
        }

        &:active {
            outline: none;
        }

        &.current {
            //border: 5px solid $link_color_alt;
            //		background: linear-gradient(to bottom, #f3f3f3 2.9%, #ffffff 40.6%, #f9f9f9 55.8%, #f7f7f7 100%) #dbdbdb;
            background: white;
            text-shadow: 2px 3px 5px #999;
            border: 3px solid $link_color_alt;
            // outline: 1px solid $link_color_alt;
            // outline-offset: -4px;
            // outline-width: 2px;
        }

        &.clicked {
            color: $link_color_alt;
            text-shadow: 2px 3px 5px #999;
            border: 3px solid $link_color;
            animation-duration: 600ms;
            animation-name: activepulse;
            animation-iteration-count: infinite;
            animation-direction: alternate;

        }
    }

    &.alarm {
        animation-duration: 500ms;
        animation-name: alarmblink;
        animation-iteration-count: infinite;
        animation-direction: alternate;
    }

    &.warning {
        animation-duration: 750ms;
        animation-name: warningblink;
        animation-iteration-count: infinite;
        animation-direction: alternate;
    }

    @keyframes alarmblink {
        from {
            color: #494949;
        }

        to {
            color: red;
        }
    }

    @keyframes warningblink {
        from {
            color: #494949;
        }

        to {
            color: #adad01;
        }
    }

    @keyframes activepulse {
        0% {
            border: 3px solid #0ff
        }

        100% {
            border: 3px solid #0aa
        }
    }

    //}
}
