& {
    box-sizing: border-box;

    table {
        table-layout: fixed;
        width: 100%;

        &:hover {
            td {
                color: rgba(255, 255, 255, 0.5) !important;
            }
        }

        td {
            // border: 1px solid #444;
            font-size: 10px;
            transition: color 5s;
            //text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
            color: rgba(255, 255, 255, 0.1);

            &.weekend {
                //s  background-color: #111; // <-- gets overwritten
                color: rgba(255, 255, 255, 0.1);
            }

            &.today {
                border-color: $link_color;
                font-weight: bold;
                color: white;
            }

            &.missing {
                opacity: 0.6;
                // outline: 1px dashed red;
                background-color: #333;
                background: repeating-linear-gradient(45deg,
                        #111,
                        #111 7px,
                        #444 7px,
                        #444 12px);

            }
			// &.notverified {
			// 	outline: 1px dashed green;
			// }
            &.loading {
                outline: 1px dashed #555;
            }

            &.future {
                opacity: 0.4;
            }



            &.warning {
                border: 1px solid yellow;
            }

            &.alarm {
                border: 1px solid red;
            }
        }

        th {
            width: 65px;

            &.weekend {
                //s  background-color: #111; // <-- gets overwritten
                color: rgba(255, 255, 255, 0.4);
            }
        }
    }
}