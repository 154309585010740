#DT {
	#HELPPANEL {
		overflow: auto;
	}
}

#DT {
	#DETAILS,
	#HELPPANEL {
		#HELP {
			p {
				margin        : 5px;
				line-height   : 20px;
				padding-bottom: 10px;
				text-align    : justify;
				z-index       : 2;
			}

			pre {
				background-color: white;
				padding         : 10px;
				font-size       : 10px;
			}

			dt {
				font-family: monospace;
				font-weight: bold;
			}

			h2 {
				border-bottom: 3px solid black;
			}

			h3 {
				border-bottom: 2px solid black;
			}

			.ticket {
				color    : #777;
				font-size: 14px;
			}

			table {
				margin-left  : 10px;
				margin-right : 10px;
				font-size    : 14px;
				width        : inherit;
				border-bottom: 1px solid black;
				margin-bottom: 10px;
				width        : 95%;

				th {
					background-color: #ccc;
					border-bottom   : 1px solid black;
				}

				td {
					text-align    : left;
					vertical-align: top;
					border-bottom : 1px solid #aaa;
				}
			}

			.watermark {
				display: none;
				// text-align: center;
				// font-size: 150px;
				// text-shadow: 2px 2px 1px #fff;
				// color: #999;
				// width: 100%;
				// margin-top: 20px;
				// position: fixed;
				// bottom: 10px;
				// z-index: 0;
				// opacity: 0.5;
			}
		}
	}

	#CONTENT {
		#HELP {
			p {
				text-align    : justify;
				padding-bottom: 10px;
				line-height   : 20px;
			}
		}
	}

	#HELP {
		a {
			text-decoration: underline;

			&:visited {
				color: #035;
			}
		}

		#topology {
			width        : 50%;
			border       : 5px dashed #ccc;
			padding      : 15px;
			border-radius: 10px;
			margin       : 15px auto;
			overflow     : hidden;

			img {
				height  : 16px;
				position: relative;
				top     : 2px;
			}
		}
		$border-width: 1px;
		$reverse     : true;

		.tree {
			@if $reverse {
				transform       : rotate(180deg);
				transform-origin: 50%;
			}
		}

		.tree ul {
			position   : relative;
			padding    : 1em 0;
			white-space: nowrap;
			margin     : 0 auto;
			text-align : center;

			&::after {
				content: '';
				display: table;
				clear  : both;
			}
		}

		.tree li {
			display        : inline-block; // need white-space fix
			vertical-align : top;
			text-align     : center;
			list-style-type: none;
			position       : relative;
			padding        : 1em 0.5em 0;

			&::after,
			&::before {
				content   : '';
				position  : absolute;
				top       : 0;
				right     : 50%;
				border-top: $border-width solid #ccc;
				width     : 50%;
				height    : 1em;
			}

			&::after {
				right      : auto;
				left       : 50%;
				border-left: $border-width solid #ccc;
			}

			&:only-child::after,
			&:only-child::before {
				display: none;
			}

			&:only-child {
				padding-top: 0;
			}

			&:first-child::before,
			&:last-child::after {
				border: 0 none;
			}

			&:last-child::before {
				border-right : $border-width solid #ccc;
				border-radius: 0 5px 0 0;
			}

			&:first-child::after {
				border-radius: 5px 0 0 0;
			}
		}

		.tree ul ul::before {
			content    : '';
			position   : absolute;
			top        : 0;
			left       : 50%;
			border-left: $border-width solid #ccc;
			width      : 0;
			height     : 1em;
		}

		.tree li a {
			border         : $border-width solid #ccc;
			padding        : 0.5em 0.75em;
			text-decoration: none;
			display        : inline-block;
			border-radius  : 5px;
			color          : #333;
			position       : relative;
			top            : $border-width;
			@if $reverse {
				transform: rotate(180deg);
			}
		}

		.tree li a:hover,
		.tree li a:hover+ul li a {
			background: #e9453f;
			color     : #fff;
			border    : $border-width solid #e9453f;
		}

		.tree li a:hover + ul li::after,
		.tree li a:hover + ul li::before,
		.tree li a:hover + ul ul::before,
		.tree li a:hover + ul::before {
			border-color: #e9453f;
		}

		#toc {
			display       : flex;
			flex-wrap     : wrap;
			flex-direction: row;

			a {
				text-decoration: none;
			}

			.group {
				flex-grow       : 1;
				flex-basis      : 1;
				background-color: #eee;
				margin          : 20px;
				padding         : 10px;
				border-radius   : 5px;

				li {
					padding: 5px;
				}
			}
		}

		#logo {
			width  : 300px;
			float  : right;
			padding: 10px;
		}

		.bottombarbutton {
			color           : black;
			background-color: #ccc;
			white-space     : nowrap;
			border          : 1px solid black;
			padding         : 3px;
			font-size       : 10px;
			box-shadow      : 2px 2px 5px rgba(0,0,0,0.5);
			font-weight     : bold;
			position        : relative;
			top             : -2px;
		}

		#key {
			a {
				color: $link_color_alt;
			}
			background-color: #333;
			color           : white;
			position        : relative;
			left            : -15px;
			top             : -15px;
			width           : calc(100% + 30px);
			font-size       : 16px;
			padding-bottom  : 10px;
			padding-top     : 8px;

			tr {
				line-height: 33px;

				td {
					padding-left: 10px;
				}
			}
		}
	}
}
