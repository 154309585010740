#DT {
    #DEVICE_LIBRARY {
        box-sizing: border-box; // display: flex;

        // flex-wrap: wrap;
        .rightmenu {
            float: right;
            color: #aaa;

            .menuitem {
                float: right;
                padding-right: 10px;
            }
        }

        #prefab {
            .device_type {
                min-width: 100px;
                width: 100px;
                border-radius: 5px;
                border-radius: 15px;

                #img {
                    width: 100px;
                    height: 100px;
                    display: table-cell;
                    text-align: center;
                    vertical-align: middle;

                    img {
                        width: 100%;
                    }
                }
            }
        }

        .device_library {
            background-color: white;
            box-sizing: border-box;
            padding-bottom: 0;
            margin-bottom: 15px;
            overflow: hidden;
            border: 3px solid black;
            border-radius: 5px;
            margin-left: 5px;
            margin-right: 5px;
            flex-grow: 1;

            &.expanded {
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;

            }

			#vendor {
				background-color: #ddd;
				padding: 5px;
				// color: #ddd;
				font-size: 12px;
				border-bottom: 2px solid black;
			}

			#header {
                background-color: black;
                color: white;
                margin-bottom: 0;
                padding: 5px;
                overflow: hidden;


                #typefilter {
                    float: right; //padding-right: 30px;

                    .filter {
                        float: left;
                        padding-right: 10px;
                        cursor: pointer;

                        &.on {
                            color: #fff;
                        }

                        &.off {
                            opacity: 0.5;
                        }

                        &#search {
                            margin-left: 10px;
                            margin-right: 5px;
                        }
                    }
                }

                .link {
                    color: $link_color_alt;
                    padding-left: 10px;
                    border-left: 1px solid $lighter;

                    &:last-child {
                        border-left: none;
                    }

                    &:hover {
                        color: white;
                    }
                }

                #small_devices {
                    .device {
                        text-align: center;
                        margin-right: 5px;
                        padding: 2px 5px;
                        border-radius: 5px;
                        background-color: #222;
                        float: right;
                        font-size: 10px;
                        color: white;
                        cursor: pointer;

                        .value {
                            color: #999;
                        }
                    }

                    .ONLINE {
                        border: 2px solid #0C0;
                    }

                    .OFFLINE {
                        border: 2px solid red;
                    }
                }
            }

            #devices {
                display: flex;
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }
    }
}