#DT {
    .badjson {
        background-color: #fee;
    }
    #DETAILS {
        #DATABASE {
            height: 100%;
            textarea {
                box-sizing: border-box;
                position: absolute;
                height: auto;
                right: 15px;
                top: 63px;
                bottom: 50px;
                left: 5px;
                width: 300px;
                font-family: monospace;
                tab-size: 3;
                white-space: pre;
            }
        }
    }
    #DATABASE {
        $noborder: 1px solid rgba(0, 0, 0, 0);
        $border: 1px solid black;
        display: flex;
        flex-direction: column;
        height: 98vh;
        table {
            // tr:nth-child(even) {
            //     background-color: #f9f9f9;
            // }
            .model_name {
                text-align: left;
                font-weight: bold;
                color: black;
            }
            .active {
                color: black;
            }
            th {
                text-transform: capitalize;
            }
            th.sort {
                cursor: pointer;
                white-space: nowrap;
                overflow: hidden;
                i {
                    color: #555;
                    padding-left: 5px;
                }
            }
        }
        #db_filter {
            background-color: #eee;
            border-left: $border;
            border-right: $border;
            border-bottom: $border;
            overflow: hidden;
            padding-left: 5px;
            padding-right: 5px;
            #tools {
                border-top: 2px solid #000;
                padding: 5px;
                margin: -2px;
                font-weight: bold;
                #right_tools {
                    div {
                        float: left;
                    }
                    #search {
                        padding-right: 5px;
                        position: relative;
                        top: -3px;
                    }
                    .switch {
                        float: left;
                        margin-top: -5px; //background-color: #ccc;
                        padding: 2px;
                        .option {
                            padding: 0 5px;
                            margin: 2px;
                            float: left; //background-color: #ccc;
                            //@at-rootborder-radius: 3px;
                            cursor: pointer;
                            &.selected {
                                background-color: #ccc;
                                outline: 4px solid #ccc;
                            }
                        }
                    }
                }
                .tool {
                    padding: 5px;
                    color: #069;
                    margin-left: 10px;
                    cursor: pointer;
                    font-weight: normal;
                    &:hover {
                        background-color: rgba(0, 0, 0, 0.1);
                    }
                }
            }
            .filter_row {
                display: flex;
                padding: 5px;
                padding-bottom: 10px;
                flex-wrap: wrap; // border-bottom: 2px solid $darker;
                max-height: 100px;
                font-size: 13px;
                background-color: #eee;
                overflow-y: auto;
                &:nth-child(even) {
                    background-color: #e9e9e9;
                }
                &:last-child {
                    border-bottom: none;
                }
                .filter {
                    flex: 1;
                    cursor: pointer;
                    white-space: nowrap;
                    padding: 3px 5px;
                    text-align: center;
                    border-right: 1px dashed $darker;
                    a {
                        pointer-events: none;
                    }
                    &:hover {
                        background-color: $darker;
                    }
                    &.off {
                        color: #aaa;
                    }
                    &:last-child {
                        border: none;
                    }
                }
            }
        }
        #db_menu {
            color: red;
            display: flex;
            width: 100%;
            overflow: hidden;
            box-sizing: border-box;
            .menuitem {
                flex-grow: 1;
                text-align: center;
                padding: 5px 10px;
                color: $link_color;
                cursor: pointer;
                border-left: $noborder;
                border-right: $noborder;
                border-top: $noborder;
                border-bottom: $border;
                white-space: nowrap;
                &.current {
                    border-left: $border;
                    border-right: $border;
                    border-top: $border;
                    border-bottom: $noborder;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    background-color: #eee;
                }
            }
        }
        .main_content {
            // border        : 1px solid red;
            // display       : flex;
            // flex-direction: column;
            flex: 1;
            overflow: scroll;
            box-sizing: border-box; //.database_view {}
        }
        .database_view {
            img {
                height: 15px;
            }
            td {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                white-space: nowrap;
                color: inherit;
                font-size: inherit;
                max-width: 250px;
            }
        }
        .table_view {
            td {
                cursor: pointer;
            }
        }
        .json_view {
            height: 100%;
            .json {
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                font-size: 15px;
                font-family: monospace;
            }
            #controlbox {
                position: absolute;
                border: 1px solid #ccc;
                right: 40px;
                margin-top: 20px;
                background-color: rgba(220, 220, 220, 0.8);
                box-shadow: 7px 7px 23px rgba(0, 0, 0, 0.1);
                z-index: 10;
                border-radius: 4px;
                &>div {
                    display: inherit;
                    margin: 5px;
                }
            }
        }
        .spreadsheet_view {
            .fontawesomechooser {
                border: 1px solid #ccc;
                text-align: center;
                width: 19px;
                margin-top: 1px;
                .preview {
                    font-size: 15px;
                    padding: 0;
                    margin: 0;
                    height: 17px;
                }
            }
            .icon,
            .image_url,
            .glyph {
                input {
                    width: calc(100% - 25px);
                }
            }
            .color {
                input {
                    width: 80px;
                }
            }
            input {
                width: 100%;
                box-sizing: border-box; //border: none;
                margin: 1px;
                &:focus {
                    border: 2px solid #999;
                    outline: none;
                }
            }
            table {
                width: 95%;
            }
            tr {
                box-shadow: none;
                td {
                    padding: 0px 1px;
                    border-bottom: none;
                }
                th {
                    color: inherit;
                    font-size: inherit;
                }
                &.hover {
                    background-color: rgba(0, 0, 0, 0.2);
                }
            }
        }
    }
}
